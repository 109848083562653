import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import img from "../Assets/Images/404.png";
import { useTranslation } from "react-i18next";

function NotFound() {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);

  return (
    <div className="flex flex-col items-center justify-center gap-2 w-full h-[78vh]">
      <img
        src={img}
        alt=""
        className="object-cover pt-20 w-[50vw] h-[50vw] lg:w-[25vw] lg:h-[25vw]"
      />
      <h1 className="font-semibold mb-4" dangerouslySetInnerHTML={{
        __html: t("notFound").replace(/\n/g, "<br />"),
      }}
      style={{ fontSize: "clamp(1.2rem, 1.2vw, 4rem)" }}></h1>
      <Link to="" replace>
      <button className="mt-5 py-2 px-6 rounded-md border-[1px] bg-[#0A66B0] border-[#0A66B0] text-[#fff] hover:bg-[#fff] hover:text-[#0A66B0] font-[400]"
          style={{ fontSize: "clamp(1.1rem, 1.1vw, 4rem)" }}>
          {t("goToHome")}
        </button>
      </Link>
    </div>
  );
}

export default NotFound;
