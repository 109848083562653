import React, { useEffect } from "react";
import Banner from "../Components/Order/Banner";
import Form from "../Components/Order/Form";
import { usePagesBanners } from "../Context/PagesBannersContext";

function Order() {
  const { isBanner } = usePagesBanners();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);

  return (
    <div className="flex flex-col mx-[2rem] lg:mx-[7rem] md:mx-[4rem] items-center gap-7">
      <Banner isBanner={isBanner?.data[3]} />
      <Form />
    </div>
  );
}

export default Order;
