import React, { useEffect } from "react";
import Banner from "../Components/Career/Banner";
import DetailsCareer from "../Components/Career/DetailsCareer";
import { useParams } from "react-router-dom";
import { useCareers } from "../Context/CareersContext";
import { usePagesBanners } from "../Context/PagesBannersContext";

function Career() {
  const { id } = useParams();
  const { isBanner } = usePagesBanners();
  const { getCareerById, isCareerById } = useCareers();
  // console.log(isCareerById);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
    getCareerById(id);
  }, []);
  return (
    <div className="flex flex-col mx-[2rem] lg:mx-[7rem] md:mx-[4rem] gap-7 overlay">
      <Banner isCareerById={isCareerById?.data?.title} isBanner={isBanner?.data[5]}/>
      <DetailsCareer isCareerById={isCareerById} />
    </div>
  );
}

export default Career;
