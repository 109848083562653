import React, { useEffect } from "react";
import { useServices } from "../../Context/ServicesContext";
import { useNavigate, useParams } from 'react-router-dom';

function SectionOne({ isServices }) {

  return (
    <div className="flex flex-col pt-5">
      {isServices?.map((service, index) => (
        <div className="flex flex-col items-center justify-between lg:flex-row gap-14 my-5"
          key={index}
        >
          {index % 2 ? (
            <>
              <div
                data-aos="fade-right"
                data-aos-easing="linear"
                data-aos-duration="1000"
                data-aos-delay="500"
              >
                <img
                  src={service?.image_url}
                  alt=""
                  className="rounded-md w-[70vw] md:w-[50vw] lg:max-w-[30vw] mx-auto"
                />
              </div>
              <div
                data-aos="fade-left"
                data-aos-easing="linear"
                data-aos-duration="1000"
                data-aos-delay="500"
                className="description flex flex-col gap-5 "
              >
                <h1 className="text-start text-[#0A66B0] font-[600]"
                  style={{ fontSize: "clamp(1.6rem, 1.6vw, 4rem)" }}>
                  {service.title}
                </h1>
                <p
                  className="text-start text-[#141414]"
                  style={{ fontSize: "clamp(1.1rem, 1.1vw, 4rem)" }}
                  dangerouslySetInnerHTML={{
                    __html: service?.description.replace(/\n/g, "<br />"),
                  }}>
                </p>
              </div>
            </>
          ) : (
            <>
              <div
                data-aos="fade-right"
                data-aos-easing="linear"
                data-aos-duration="1000"
                data-aos-delay="500"
                className="description flex flex-col gap-5 order-1 lg:order-0"
              >
                <h1 className="text-start text-[#0A66B0] font-[600]"
                  style={{ fontSize: "clamp(1.6rem, 1.6vw, 4rem)" }}>
                  {service.title}
                </h1>
                <p
                  className="text-start text-[#141414]"
                  style={{ fontSize: "clamp(1.1rem, 1.1vw, 4rem)" }}
                  dangerouslySetInnerHTML={{
                    __html: service?.description.replace(/\n/g, "<br />"),
                  }}>
                </p>
              </div>
              <div
                data-aos="fade-left"
                data-aos-easing="linear"
                data-aos-duration="1000"
                data-aos-delay="500"
                className="order-0 lg:order-1"
              >
                <img
                  src={service?.image_url}
                  alt=""
                  className="rounded-md w-[70vw] md:w-[50vw] lg:max-w-[30vw] mx-auto"
                />
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  );
}

export default SectionOne;
