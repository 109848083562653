import React, { useEffect } from "react";
import Banner from "../Components/Products/Banner";
import SectionOne from "../Components/Products/SectionOne";
import SectionTwo from "../Components/Products/SectionTwo";
import SectionThree from "../Components/Products/SectionThree";
import SectionFour from "../Components/Products/SectionFour";
import SectionFive from "../Components/Products/SectionFive";
import { usePagesBanners } from "../Context/PagesBannersContext";

function Products() {
  const { isBanner } = usePagesBanners();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);
  return (
    <div className="flex flex-col mx-[2rem] lg:mx-[7rem] md:mx-[4rem] items-center gap-10">
      <Banner isBanner={isBanner?.data[3]} />
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <SectionFive />
    </div>
  );
}

export default Products;
