import React from "react";
import "aos/dist/aos.css";

function SectionTwo({ isAbout }) {
  return (
    <div className="flex flex-col items-center justify-between lg:flex-row gap-14 my-5">
      <div
        data-aos="fade-right"
        data-aos-duration="1000"
        data-aos-easing="linear"
        data-aos-delay="500"
      >
        <img
          src={isAbout?.image_url}
          alt=""
          className="rounded-lg w-[70vw] md:w-[50vw] lg:max-w-[30vw] mx-auto"
        />
      </div>
      <div className="flex flex-col gap-5">
        <div
          data-aos="fade-left"
          data-aos-easing="linear"
          data-aos-duration="1000"
          data-aos-delay="500"
          className="flex flex-col items-start"
        >
          <p
            style={{ fontSize: "clamp(1.1rem, 1.1vw, 4rem)" }}
            className="text-start text-[#141414] font-[400]"
          >
            {isAbout?.excerpt != null ? isAbout?.excerpt : null}
          </p>
          {isAbout?.excerpt != null ? <span className="border-b border-[#141414] my-2 w-24"></span> : null}
          <p
            style={{ fontSize: "clamp(1.1rem, 1.1vw, 4rem)" }}
            className="text-start text-[#141414] font-[300] leading-relaxed"
            dangerouslySetInnerHTML={{
              __html: isAbout?.description.replace(/\n/g, "<br />"),
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default SectionTwo;
