import React, { useEffect } from "react";
import Banner from "../Components/Service/Banner";
import SectionOne from "../Components/Service/SectionOne";
import { useServices } from "../Context/ServicesContext";
import { useNavigate, useParams } from "react-router-dom";
import { usePagesBanners } from "../Context/PagesBannersContext";

function Service() {
  const { getServices, isServices } = useServices();
  const { isBanner } = usePagesBanners();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      await getServices(id); // Fetch project data when the component is mounted
    };
    window.scrollTo({ top: 0, behavior: "auto" });
    fetchData();
  }, [id]);

  if (isServices === null) {
    navigate(`/NotFound`, { replace: true }); // Redirect if there's no data
    return null; // Don't render anything while navigating
  }

  return (
    <div className="flex flex-col mx-[2rem] lg:mx-[7rem] md:mx-[4rem] items-center gap-7 overlay">
      <Banner isServices={isServices?.data} isBanner={isBanner?.data[1]} />
      <SectionOne isServices={isServices?.data} />
    </div>
  );
}

export default Service;
