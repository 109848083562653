import React, { useRef, useState } from "react";
import contact from "../../Assets/Images/contact.png";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { newBaseUrl } from "../../Consts";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";

function Form() {
  const { t } = useTranslation();
  const formRef = useRef(null); // Reference to the form element
  const [showPopup, setShowPopup] = useState(false); // State to toggle the popup
  const [loading, setLoading] = useState(false)

  const handleSendClick = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    setLoading(true);
    try {
      const body = {
        'name': document.getElementById('name').value,
        'email': document.getElementById('email').value,
        'phone': document.getElementById('phone').value,
        'subject': document.getElementById('subject').value,
        'content': document.getElementById('content').value,
      };

      const response = await axios.post(`${newBaseUrl}/api/contact`, body);
      setLoading(false);
      setShowPopup(true); // Show popup on success
      formRef.current.reset();
    } catch (err) {
      // Check if there are validation errors in the response
      if (err.response && err.response.data && err.response.data.errors) {
        // Loop through each error message and display it
        const errors = err.response.data.errors; // Assuming `errors` is an object or array
        for (const key in errors) {
          if (errors.hasOwnProperty(key)) {
            errors[key].forEach((message) => {
              toast.error(message); // Display each error message
            });
          }
        }
      } else if (err.response && err.response.data && err.response.data.message) {
        // Display a single error message if available
        toast.error(err.response.data.message);
      } else {
        // Handle other errors
        toast.error("An unexpected error occurred.");
      }
      setLoading(false)
    }
  };

  const closePopup = () => {
    setShowPopup(false); // Close the popup
  };

  return (
    <div className="flex flex-col lg:flex-row items-start justify-between mt-16 mb-10 w-full rounded-md">
      <ToastContainer autoClose={5000} pauseOnFocusLoss={false} pauseOnHover={false} />
      <form ref={formRef} onSubmit={handleSendClick} className="w-full me-10 order-1 lg:order-0"
      >
        <div className="flex flex-col items-start">
          <label
            htmlFor="name"
            className="text-[#141414] text-start font-[600] py-3"
            style={{ fontSize: "clamp(1rem, 1vw, 4rem)" }}
          >
            {t("Name")}
          </label>
          <input
            type="text"
            id="name"
            placeholder={t("Enter Your Name")}
            className="border-[1px] py-3 px-5 w-full rounded-md"
            style={{ fontSize: "clamp(.75rem, .75vw, 4rem)" }}
            required
          />
        </div>
        <div className="flex flex-col items-start">
          <label
            htmlFor="email"
            className="text-[#141414] text-start font-[600] py-3"
            style={{ fontSize: "clamp(1rem, 1vw, 4rem)" }}
          >
            {t("Email")}
          </label>
          <input
            type="email"
            id="email"
            placeholder={t("Enter Your Email")}
            className="border-[1px] py-3 px-5 w-full rounded-md"
            style={{ fontSize: "clamp(.75rem, .75vw, 4rem)" }}
            required
          />
        </div>
        <div className="flex flex-col items-start">
          <label
            htmlFor="phone"
            className="text-[#141414] text-start font-[600] py-3"
            style={{ fontSize: "clamp(1rem, 1vw, 4rem)" }}
          >
            {t("Phone")}
          </label>
          <input
            id="phone"
            placeholder={t("EnterYourPhone")}
            className="border-[1px] py-3 px-5 w-full rounded-md"
            style={{ fontSize: "clamp(.75rem, .75vw, 4rem)" }}
            required
          />
        </div>
        <div className="flex flex-col items-start">
          <label
            htmlFor=""
            className="text-[#141414] text-start font-[600] py-3"
            style={{ fontSize: "clamp(1rem, 1vw, 4rem)" }}
          >
            {t("Subject")}
          </label>
          <input
            type="text"
            id="subject"
            placeholder={t("EnterYourSubject")}
            className="border-[1px] h-[40px] w-full lg:w-full lg:h-[60px] px-5 rounded-md"
            style={{ fontSize: "clamp(.75rem, .75vw, 4rem)" }}
          />
        </div>
        <div className="flex flex-col items-start">
          <label
            htmlFor=""
            className="text-[#141414] text-start font-[600] py-3"
            style={{ fontSize: "clamp(1rem, 1vw, 4rem)" }}
          >
            {t("Message")}
          </label>
          <textarea
            placeholder={t("EnterYourMessage")}
            id="content"
            className="border-[1px] w-full lg:w-full h-[140px] px-5 py-3 rounded-md"
            style={{ fontSize: "clamp(.75rem, .75vw, 4rem)" }}
          />
        </div>
        <button
          type="submit"
          className="bg-[#0A66B0] hover:bg-transparent border-[#0A66B0 border-[1px] block mx-auto w-full hover:border-[#0A66B0] text-[#FFF] hover:text-[#0A66B0] font-[400] py-2 rounded-md mt-10"
          style={{ fontSize: "clamp(1rem, 1vw, 4rem)" }}
          disabled={loading}
        >
          {t("Send")}
        </button>
      </form>
      <div className="order-0 lg:order-1">
        <img
          src={contact}
          className="rounded-lg w-[100vw] md:w-[100vw] lg:max-w-[40vw] mx-auto"
        />
      </div>
      {/* Popup */}
      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="flex flex-col bg-white p-5 rounded-lg shadow-lg text-center justify-center h-[200px]">
            <p className="text-lg font-bold">{t("contactSentSuccessfully")}</p>
            <div className="flex lg:flex-row flex-col justify-between gap-5 mt-10">
              <Link
                to={"/"}
              >
                <button
                  onClick={closePopup}
                  className="bg-[#0A66B0] hover:bg-transparent border-[#0A66B0 border-[1px] block mx-auto w-[80vw] lg:w-[10vw] hover:border-[#0A66B0] text-[#FFF] hover:text-[#0A66B0] font-[400] py-2 rounded-md"
                >
                  {t("goToHome")}
                </button>
              </Link>
              <button
                onClick={closePopup}
                className="py-2 w-[80vw] lg:w-[10vw] rounded-md border-[1px] border-[#0A66B0] mx-auto text-[#0A66B0] hover:bg-[#0A66B0] hover:text-[#FFF] font-[400]"
              >
                {t("close")}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Form;
