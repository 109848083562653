import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Cards({ isProductsByCategory }) {
  const { t } = useTranslation();
  const [selectedCategory, setSelectedCategory] = useState();
  const location = useLocation();
  const { id } = location.state || {};

  useEffect(() => {
    if (id == 'all') {
      setSelectedCategory('all')
    } else {
      setSelectedCategory(id)
    }
  }, []);

  return (
    <div className="flex flex-col gap-12 my-10 justify-center w-[100%]">
      <div className="flex gap-[0.8rem] justify-center items-center lg:gap-10"
        data-aos="fade-in"
        data-aos-easing="linear"
        data-aos-duration="1000"
        data-aos-delay="500"
        style={{ fontSize: "clamp(1.1rem, 1.1vw, 4rem)" }}>
        <button
          onClick={() => setSelectedCategory("all")}
          className={
            selectedCategory === "all"
              ? "active text-[#0A66B0] font-[600]"
              : "font-[600]"
          }
          key="All"

        >
          {t("all")}
        </button>
        {isProductsByCategory?.data2?.map((category) => (
          <button
            onClick={() => setSelectedCategory(category.id)}
            className={
              selectedCategory === category.id
                ? "active text-[#0A66B0] font-[600]"
                : "font-[600]"
            }
            key={category.id}
          >
            {category.name}
          </button>
        ))}
      </div>

      {/* No Data Fallback */}
      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-6 gap-6 mt-10">
        {isProductsByCategory?.data?.map(
          (item) =>
            (selectedCategory === "all" ||
              item.product_cat_id == selectedCategory) && (
              <div
                key={item.id}
                className="flex justify-between items-start gap-[2rem] lg:gap-10 overflow-hidden"
                dir="auto"
              >
                <div
                  data-aos="fade-in"
                  data-aos-duration="1000"
                  data-aos-easing="linear"
                  data-aos-delay="500"
                  className="relative group flex flex-col items-start gap-[15px] w-full lg:w-[25vw]"
                >
                  {/* Image Container */}
                  <div className="rounded-md overflow-hidden">
                    <Link to={`/product/${item.id}`}>
                      <img
                        src={item.image_url}
                        alt={item.title}
                        className="w-[100vw] h-[40vw] sm:w-[30vw] sm:h-[30vw] md:w-[50vw] md:h-[35vw] lg:w-[50vw] lg:h-[22vw] xl:w-[50vw] xl:h-[14vw] rounded-md object-cover transform transition-transform duration-500 ease-in-out group-hover:scale-110"
                      />
                    </Link>
                  </div>
                  {/* Product Title */}
                  <h1
                    className="text-[#212121] group-hover:text-[#0A66B0] transition-colors duration-300"
                    style={{ fontSize: "clamp(1.1rem, 1.1vw, 4rem)" }}
                  >
                    {item.title}
                  </h1>
                </div>
              </div>
            )
        )}
      </div>
    </div>
  );
}

export default Cards;
