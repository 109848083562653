import React from "react";
import { t } from "i18next";
import { initialLanguage } from "../../Consts";

function Banner( {isBanner} ) {
  return (
    <div className="relative w-full h-[200px] md:h-[200px] lg:h-[250px] xl:h-[350px] mt-[10rem]"
      data-aos="fade-in"
      data-aos-easing="linear"
      data-aos-duration="1000"
      data-aos-delay="500">
      <img src={isBanner?.image_url} alt="" className={initialLanguage == "ar" ? "flip-horizontal w-full h-[200px] md:h-[200px] lg:h-[250px] xl:h-[350px] object-cover rounded-md" : "w-full h-[200px] md:h-[200px] lg:h-[250px] xl:h-[350px] object-cover rounded-md"} />
      <div className="absolute inset-0 bg-[#00000060] h-[200px] md:h-[200px] lg:h-[250px] xl:h-[350px] rounded-md"></div>
      <div
        className="lg:about-title absolute inset-[2rem] lg:inset-[4rem] flex flex-col justify-center items-start"
      >
        <h1 className="text-[#fff] drop-shadow-text font-[700]"
          style={{ fontSize: "clamp(2rem, 3vw, 4rem)" }}>
          {t("Brands")}
        </h1>
      </div>
    </div>
  );
}

export default Banner;
