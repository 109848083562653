import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useProducts } from "../../Context/ProductsContext";
import { useTranslation } from "react-i18next";

function SectionFive() {
  const { getProductsCategory, isProductsCategory } = useProducts();
  const { t } = useTranslation();

  // Fetch product categories on component mount
  useEffect(() => {
    getProductsCategory();
  }, []);

  const responsive = {
    largeDesktop: {
      breakpoint: { max: 4000, min: 1600 }, // Screens larger than 1600px
      items: 3, // Show 6 items on large desktop screens
    },
    desktop: {
      breakpoint: { max: 1600, min: 1200 }, // Screens between 1200px and 1600px
      items: 3, // Show 5 items on desktop screens
    },
    tablet: {
      breakpoint: { max: 1199, min: 768 }, // Screens between 768px and 1199px
      items: 3, // Show 3 items on tablet screens
    },
    smallTablet: {
      breakpoint: { max: 1024, min: 768 }, // More specific tablet range (768px to 1024px)
      items: 3, // Show 2 items on small tablets
    },
    mobile: {
      breakpoint: { max: 768, min: 576 }, // Screens between 576px and 768px
      items: 3, // Show 2 items on mobile screens (larger than 576px but smaller than 768px)
    },
    smallMobile: {
      breakpoint: { max: 575, min: 0 }, // Screens smaller than 576px
      items: 1, // Show 1 item on small mobile screens
    },
  };

  return (
    <div className="flex flex-col gap-5 w-full">
      {/* Section Header */}
      <div className="text-start mb-4"
        data-aos="fade-in"
        data-aos-duration="1000"
        data-aos-easing="linear"
        data-aos-delay="500">
        <h1 className="text-[#0A66B0] font-semibold"
          style={{ fontSize: "clamp(2rem, 2.25vw, 4rem)" }}>
          {t("Categories")}
        </h1>
      </div>
      {/* Carousel */}
      <Carousel
        responsive={responsive}
        infinite={isProductsCategory?.data?.length > 3}
        autoPlay={true}
        autoPlaySpeed={3000}
        showDots={false}
        arrows={true}
        containerClass="carousel-container relative z-0" // Ensure carousel stays below the navbar
        itemClass="px-3"
      >
        {isProductsCategory?.data?.length > 0 ? (
          isProductsCategory.data.map((category) => (
            <div key={category.id}
              data-aos="fade-left"
              data-aos-duration="1000"
              data-aos-easing="linear"
              data-aos-delay="500" >
              <Link to={`/products/all`} state={{ id: category.id }}>
                <div className="flex flex-col gap-3 items-center cursor-pointer">
                  <div className="hover-overlay rounded-md">
                    <img
                      src={category.image_url}
                      alt={category.title}
                      className="w-[100vw] h-[75vw] sm:h-[30vw] sm:w-[50vw] md:w-[50vw] md:h-[25vw] lg:w-[50vw] lg:h-[25vw] xl:w-[50vw] xl:h-[25vw] rounded-md object-cover"
                    />
                  </div>
                  <h3 className="text-[#141414] font-normal"
                    style={{ fontSize: "clamp(1.2rem, 1.2vw, 4rem)" }}>
                    {category.name}
                  </h3>
                </div>
              </Link>
            </div>
          ))
        ) : (
          <div></div>
        )}
      </Carousel>
    </div>
  );
}

export default SectionFive;
