import React from "react";
import career from "../../Assets/Images/careers.jpg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function SectionOne() {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col lg:flex-row items-center gap-10 my-10 w-full">
      <div
        data-aos="fade-right"
        data-aos-duration="1000"
        data-aos-easing="linear"
        data-aos-delay="500"
        className="flex flex-col items-start order-1 lg:order-0"
      >
        <p className="text-start text-[#141414] font-[400]"
          style={{ fontSize: "clamp(1.5rem, 1.5vw, 4rem)" }}>
          {t("careers")}
        </p>
        <Link
          to={"/applyNow/" + 'Open Recruitment'.replace(/\//g, "-")}
        >
          <button className="bg-[#0A66B0] hover:bg-transparent border-[#0A66B0 border-[1px] hover:border-[#0A66B0] text-[#FFF] hover:text-[#0A66B0] font-[400] py-2 px-8 rounded-md mt-10"
            style={{ fontSize: "clamp(1rem, 1vw, 4rem)" }}>
            {t("SendYourCV")}
          </button>
        </Link>
      </div>
      <div data-aos="fade-left"
        data-aos-duration="1000"
        data-aos-easing="linear"
        data-aos-delay="500" className="order-0 lg:order-1">
        <img
          src={career}
          alt=""
          className="rounded-lg w-[70vw] md:w-[50vw] lg:max-w-[30vw] mx-auto"
        />
      </div>
    </div>
  );
}

export default SectionOne;
