import React, { useEffect, useState } from "react";
import about from "../../Assets/Images/about6.jpg";
import se from "../../Assets/Images/sec6.png";
import { useTranslation } from "react-i18next";

function SectionSix({ isAbout }) {
  const { t } = useTranslation();

  return (
    // 2xl:mx-[7rem] xl:mx-[7rem] lg:mx-[7rem]
    <div className="flex flex-col items-center justify-between lg:flex-row gap-14 my-5">
      <div className="description flex flex-col gap-5 order-1 lg:order-0">
        <div
          data-aos="fade-right"
          data-aos-easing="linear"
          data-aos-duration="1000"
          data-aos-delay="500"
          className="relative lg:services-title flex flex-col items-start "
        >
          <h1 className="text-[#AF1F24] font-[400]"
            style={{ fontSize: "clamp(1.6rem, 1.6vw, 4rem)" }}>
            {t("FOUNDER")}
          </h1>
          <h1 className="text-start text-[#0A66B0] font-[600]"
            style={{ fontSize: "clamp(2rem, 2.25vw, 4rem)" }}>
            {t("Amr Saleh")}
          </h1>
        </div>
        <div
          data-aos="fade-right"
          data-aos-easing="linear"
          data-aos-duration="1000"
          data-aos-delay="500"
          className="flex flex-col items-start gap-5 lg:max-w-[60vw]"
        >
          <p className="text-start text-[#141414] font-[400]"
            style={{ fontSize: "clamp(1.2rem, 1.2vw, 4rem)" }}
          >
            {isAbout?.excerpt != null ? isAbout?.excerpt : null}
          </p>
          {isAbout?.excerpt != null ? <span className="border"></span> : null}
          <p
            className="text-start text-[#141414]"
            style={{ fontSize: "clamp(1.1rem, 1.1vw, 4rem)" }}
            dangerouslySetInnerHTML={{
              __html: isAbout?.description.replace(/\n/g, "<br />"),
            }}>
          </p>
        </div>
      </div>
      <div
        data-aos="fade-left"
        data-aos-easing="linear"
        data-aos-duration="1000"
        data-aos-delay="500"
        className="order-0 lg:order-1"
      >
        <img
          src={isAbout?.image_url}
          alt=""
          className="rounded-md w-[70vw] md:w-[50vw] lg:max-w-[30vw] mx-auto"
        />
      </div>
    </div>
  );
}

export default SectionSix;
