import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { initialLanguage } from "../../Consts"

function SectionThree({ isProject }) {
  const { t } = useTranslation();

  const responsive = {
    largeDesktop: {
      breakpoint: { max: 4000, min: 1600 }, // Screens larger than 1600px
      items: 5, // Show 6 items on large desktop screens
    },
    desktop: {
      breakpoint: { max: 1600, min: 1200 }, // Screens between 1200px and 1600px
      items: 4, // Show 5 items on desktop screens
    },
    tablet: {
      breakpoint: { max: 1199, min: 768 }, // Screens between 768px and 1199px
      items: 3, // Show 3 items on tablet screens
    },
    smallTablet: {
      breakpoint: { max: 1024, min: 768 }, // More specific tablet range (768px to 1024px)
      items: 3, // Show 2 items on small tablets
    },
    mobile: {
      breakpoint: { max: 768, min: 576 }, // Screens between 576px and 768px
      items: 3, // Show 2 items on mobile screens (larger than 576px but smaller than 768px)
    },
    smallMobile: {
      breakpoint: { max: 575, min: 0 }, // Screens smaller than 576px
      items: 1, // Show 1 item on small mobile screens
    },
  };

  return (
    isProject?.length > 0 ? (
      <div className="carousel mx-[2rem] lg:mx-[7rem]">
        <div className="flex justify-between items-end py-10">
          <div className="relative">
            <h1 className="text-[#0A66B0] font-[600]"
              style={{ fontSize: "clamp(2rem, 2.25vw, 4rem)" }}>
              {t("RelatedProjects")}
            </h1>
          </div>
        </div>
        {isProject?.length > 0 ? (
          <Carousel
            responsive={responsive}
            infinite
            autoPlay
            autoPlaySpeed={3000}
            showDots={false}
            arrows
            containerClass="carousel-container"
            itemClass="px-3" // Adds spacing between items
          >
            {isProject?.map((item, index) => (
              <Link key={index} to={"/project/" + item.id}>
                <div
                  className="flex justify-between gap-[2rem] lg:gap-10 overflow-x-auto lg:overflow-hidden"
                  dir="auto"
                >
                  <div
                    data-aos="fade-left"
                    data-aos-duration="1000"
                    data-aos-easing="linear"
                    data-aos-delay="500"
                    className="relative group flex flex-col items-start w-full lg:w-[26.3vw] hover-overlay rounded-md"
                  >
                    <img
                      src={item.image_url}
                      alt=""
                      className="w-[100vw] h-[75vw] md:w-[50vw] md:h-[25vw] lg:w-[50vw] lg:h-[22vw] xl:w-[50vw] xl:h-[16vw] rounded-md object-cover"
                    />
                    <div className="absolute bottom-[4rem] 2xl:bottom-[5rem] left-[.5rem] right-0 bottom-0 flex items-center justify-start opacity-0 group-hover:opacity-100 transition-opacity">
                      <div
                        className={` ${initialLanguage === "ar"
                          ? "absolute right-[2rem] border-r-2 text-direction-arabic "
                          : "absolute left-[1rem] border-l-2 text-direction-english "
                          }`}
                      >
                        <p className="text-start border-b-2 text-[#FFF] font-[600] ps-3 py-3 "
                          style={{ fontSize: "clamp(.9rem, .9vw, 4rem)" }}>
                          {item.title}
                        </p>
                        <p className="text-start text-[#319AEF] font-[400] ps-3 py-3"
                          style={{ fontSize: "clamp(.8rem, .8vw, 4rem)" }}>
                          {item.project_owner}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </Carousel>
        ) : (
          <div></div>
        )}
      </div>)
      : <div></div>
  );
}

export default SectionThree;
