import { useEffect } from "react";
import { usePagesBanners } from "../Context/PagesBannersContext";

function BannerInitializer() {
  const { getBanner } = usePagesBanners();

  useEffect(() => {
    getBanner(); // Replace `2` with the desired ID
  }, []);

  return null; // No UI is needed; this is just to run the effect
}

export default BannerInitializer;
