import React, { useEffect } from "react";
import Banner from "../Components/TermsConditions/Banner";
import SectionOne from "../Components/TermsConditions/SectionOne";
import { usePagesBanners } from "../Context/PagesBannersContext";

function TermsConditions() {
  const { isBanner } = usePagesBanners();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);

  return (
    <div className="overlay">
      <div className="flex flex-col mx-[2rem] lg:mx-[7rem] md:mx-[4rem] items-center gap-7">
        <Banner isBanner={isBanner?.data[8]} />
        <SectionOne />
      </div>
    </div>
  );
}

export default TermsConditions;
