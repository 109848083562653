import React from "react";
import pr5 from "../../Assets/Images/1618915671610.jpg";

function SectionFour() {
  return (
    <div className="w-full"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-easing="linear"
      data-aos-delay="500">
      <img src={pr5} alt="" className="w-full h-[200px] md:h-[250px] lg:h-[300px] xl:h-[350px] 2xl:h-[400px] object-cover rounded-md" />
    </div>
  );
}

export default SectionFour;
