import React from "react";

function SectionTwo({ isProject }) {
  return (
    <div className="flex flex-col w-full">
      {isProject?.map((projectDescription, index) => (
        <div
          className="flex flex-col items-center justify-between lg:flex-row gap-14 my-5"
          key={index}
        >
          {index % 2 ? (
            <>
              <div
                data-aos="fade-right"
                data-aos-easing="linear"
                data-aos-duration="1000"
                data-aos-delay="500"
              >
                <img
                  src={projectDescription?.image_url}
                  alt=""
                  className="rounded-md w-[70vw] md:w-[50vw] lg:max-w-[30vw] mx-auto"
                />
              </div>
              <div
                data-aos="fade-left"
                data-aos-easing="linear"
                data-aos-duration="1000"
                data-aos-delay="500"
                className="description flex flex-col gap-5 "
              >
                {projectDescription.short_description != null &&
                  projectDescription.short_description.split("\n").map((line, index) => (
                    <h1 className="text-start text-[#0A66B0] font-[600]">
                      {projectDescription.short_description}
                    </h1>
                  ))}
                {projectDescription.short_description != null ? <span className="border"></span> : null}
                <p
                  className="text-start text-[#141414]"
                  style={{ fontSize: "clamp(1.2rem, 1.2vw, 4rem)" }}
                  dangerouslySetInnerHTML={{
                    __html: projectDescription?.description.replace(/\n/g, "<br />"),
                  }}>
                </p>
              </div>
            </>
          ) : (
            <>
              <div
                data-aos="fade-right"
                data-aos-easing="linear"
                data-aos-duration="1000"
                data-aos-delay="500"
                className="description flex flex-col gap-5 order-1 lg:order-0"
              >
                {projectDescription.short_description != null &&
                  projectDescription.short_description.split("\n").map((line, index) => (
                    <h1 className="text-start text-[#0A66B0] md:text-[28px] lg:text-[32px] xl:text-[36px] font-[600]">
                      {projectDescription.short_description}
                    </h1>
                  ))}
                {projectDescription.short_description != null ? <span className="border"></span> : null}
                <p
                  className="text-start text-[#141414]"
                  style={{ fontSize: "clamp(1.2rem, 1.2vw, 4rem)" }}
                  dangerouslySetInnerHTML={{
                    __html: projectDescription?.description.replace(/\n/g, "<br />"),
                  }}>
                </p>
              </div>
              <div
                data-aos="fade-left"
                data-aos-easing="linear"
                data-aos-duration="1000"
                data-aos-delay="500"
                className="order-0 lg:order-1"
              >
                <img
                  src={projectDescription?.image_url}
                  alt=""
                  className="rounded-md w-[70vw] md:w-[50vw] lg:max-w-[30vw] mx-auto"
                />
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  );
}

export default SectionTwo;
