import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

function DetailsCareer({ isCareerById }) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-5"
      style={{ fontSize: "clamp(1rem, 1vw, 4rem)" }}
      data-aos="fade-in"
      data-aos-easing="linear"
      data-aos-duration="1000"
      data-aos-delay="500">
      <div className="flex flex-col items-start gap-3"
      >
        <h1
          className="text-[#0A66B0] font-[600]"
          style={{ fontSize: "clamp(1.6rem, 1.6vw, 4rem)" }}>
          {t("JobDescription")}
        </h1>

        <p
          className="text-start text-[#141414] font-[400]"
          dangerouslySetInnerHTML={{
            __html: isCareerById?.data?.details.replace(/\n/g, "<br />"),
          }}>
        </p>
      </div>
      <div className="flex flex-col items-start gap-3"
      >
        <h1
          className="text-[#0A66B0] font-[600]"
          style={{ fontSize: "clamp(1.6rem, 1.6vw, 4rem)" }}>
          {t("Skills")}
        </h1>
        <p
          className="text-start text-[#141414] font-[400]"
          dangerouslySetInnerHTML={{
            __html: isCareerById?.data?.skills.replace(/\n/g, "<br />"),
          }}>
        </p>
      </div>
      <div className="flex flex-col items-start gap-3"
      >
        <h1
          className="text-[#0A66B0] font-[600]"
          style={{ fontSize: "clamp(1.6rem, 1.6vw, 4rem)" }}>
          {t("Education")}
        </h1>
        <p
          className="text-start text-[#141414] font-[400]"
          dangerouslySetInnerHTML={{
            __html: isCareerById?.data?.education.replace(/\n/g, "<br />"),
          }}>
        </p>
      </div>
      <span className="border"></span>
      <div className="flex flex-col text-start gap-3"
      >
        <h3 className="text-[#0A66B0] text-start font-[400]">
          {t("jobLocation")} : <span className="text-[#141414]">{isCareerById?.data?.location}</span>
        </h3>
        <h3 className="text-[#0A66B0] text-start font-[400]">
          {t("jobPosition")} : <span className="text-[#141414]">{isCareerById?.data?.position}</span>
        </h3>
        <h3 className="text-[#0A66B0] text-start font-[400]">
          {t("empStatus")} : <span className="text-[#141414]">{isCareerById?.data?.emp_status}</span>
        </h3>
      </div>
      <Link
        to={"/applyNow/" + isCareerById?.data?.title.replace(/\//g, "-")}
      >
        <button className="bg-[#0A66B0] hover:bg-transparent border-[#0A66B0 border-[1px] block me-auto hover:border-[#0A66B0] text-[#FFF] hover:text-[#0A66B0] font-[400] py-2 px-8 rounded-md mt-10"
          style={{ fontSize: "clamp(1rem, 1vw, 4rem)" }}>
          {t("ApplyNow")}
        </button>
      </Link>
    </div>
  );
}

export default DetailsCareer;
