import React, { useEffect, useState } from "react";
import banner from "../../Assets/Images/NUMPER PANNER.png";
import { useTranslation } from "react-i18next";

function SectionThree({ isAbout }) {
  const { t } = useTranslation();

  return (
    // data-aos="fade-right" data-aos-delay="500"
    <div className="relative w-full my-10"
      data-aos="fade-in"
      data-aos-easing="linear"
      data-aos-duration="1000"
      data-aos-delay="500">
      <img
        src={banner}
        alt=""
        className="w-full h-[40vh] md:h-[30vh] lg:h-[40vh] object-cover"
      />
      <div className="absolute inset-0 bg-[#00000080]"></div>
      <div className="absolute inset-0  flex flex-col justify-center items-center gap-10">
        <h1 className="text-[#fff] font-[700]"
          style={{ fontSize: "clamp(1.35rem, 3vw, 4rem)" }}>
          {t("numbers")}
        </h1>
        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-[3rem] lg:gap-[5rem]">
          {isAbout?.map((numbers, index) => (
            <div key={index} className="flex items-center flex-col">
              <span className="text-[#fff] font-[700]"
                style={{ fontSize: "clamp(1.35rem, 2.4vw, 4rem)" }}>
                +{numbers.number}
              </span>
              <span className="text-[#fff] text-[20px] lg:text-[36px] font-[600]"
                style={{ fontSize: "clamp(1.2rem, 1.3vw, 4rem)" }}>
                {numbers.title}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SectionThree;
