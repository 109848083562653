import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useHome } from "../../Context/HomeContext";
import fb from "../../Assets/Images/Frame.svg";
import ig from "../../Assets/Images/Frame (1).svg";
import li from "../../Assets/Images/Frame (2).svg";
import yt from "../../Assets/Images/Frame (3).svg";
import wu from "../../Assets/Images/Frame (4).svg";

function Footer() {
  const { isSocial, getSocial } = useHome();
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  const [images, setImages] = useState([]);

  useEffect(() => {
    getSocial('arch')
      .then(socialData => {
        // Merge the images array into the isSocial data
        const updatedIsSocial = { ...socialData, images: [fb, ig, li, yt, wu] };
        setImages(updatedIsSocial.images);
      })
      .catch(error => {
        console.error("Error fetching social data:", error);
      });
  }, []);

  return (
    <div className="mx-[2rem] lg:mx-[7rem] md:mx-[4rem] py-10 flex flex-col justify-center">
      <div className="border-t-[1px] border-[#0A66B0] py-7 flex flex-col items-center justify-between lg:gap-0 lg:flex-row md:flex-row text-[#0A66B0] font-[700] leading-[120%]"
        style={{ fontSize: "clamp(.8rem, .8vw, 4rem)" }}>
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 gap-5 justify-around w-full">
          <Link to={'/careers'}><h1>{t("Careers")}</h1></Link>
          <Link to={'/contact'}><h1>{t("Contact Us")}</h1></Link>

          <Link to={'/Terms&Conditions'}><h1>{t("Terms & Conditions")}</h1></Link>
          <Link to={'/PrivacyPolicy'}><h1>{t("Privacy Policy")}</h1></Link>
        </div>
      </div>
      <div className="flex flex-row gap-[2rem] justify-center">
        {images.map((image, index) => (
          <Link key={index} to={isSocial?.data[index]?.url} target="_blank" rel="noopener noreferrer">
            <img
              src={image}
              alt=""
              className="w-[1.3rem] h-[1.3rem]"
            />
          </Link>
        ))}
      </div>
      <div className="text-[#0A66B0] font-[600] leading-[140%] justify-between mt-5"
        style={{ fontSize: "clamp(.7rem, .7vw, 4rem)" }}>
        <h3 >
          © {currentYear} {t("Arch Sense Group. All right reserved.")}
          <br /> Designed by <span className="underline"><Link to={'https://codescreed.com'} target="_blank">Codescreed</Link></span>
        </h3>
      </div>
    </div>
  );


}

export default Footer;
