import React, { useEffect } from "react";
import Banner from "../Components/News/Banner";
import Cards from "../Components/News/Cards";
import { usePagesBanners } from "../Context/PagesBannersContext";

function News() {
  const { isBanner } = usePagesBanners();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);

  return (
    <div className="flex flex-col mx-[2rem] lg:mx-[7rem] md:mx-[4rem] items-center gap-7 overlay">
      <Banner isBanner={isBanner?.data[4]} />
      <Cards />
    </div>
  );
}

export default News;
