import React, { useEffect } from "react";
import o from "../../Assets/Images/1 (1).svg";
import th from "../../Assets/Images/1 (3).svg";
import fi from "../../Assets/Images/1 (5).svg";
import AOS from "aos";
import "aos/dist/aos.css"; // Import the AOS styles
import { useTranslation } from "react-i18next";

function Banner( {isBanner} ) {
  const { t } = useTranslation();

  const containerStyle = {
    backgroundImage: `linear-gradient(270deg, rgba(0, 0, 0, 0.60) 9.81%, rgba(0, 0, 0, 0.00) 29.72%), url(${isBanner?.image_url})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div style={containerStyle} className="relative w-full h-[200px] md:h-[200px] lg:h-[250px] xl:h-[350px] mt-[10rem] rounded-md"
      data-aos="fade-in"
      data-aos-easing="linear"
      data-aos-duration="1000"
      data-aos-delay="500">
      <div className="absolute inset-0 bg-[#00000050] h-[200px] md:h-[200px] lg:h-[250px] xl:h-[350px] rounded-md"></div>
      <div className="about-title absolute top-0 left-[1rem] lg:left-[5rem] lg:right-[3rem] right-[1rem] bottom-0 flex justify-between items-center">
        <h1 className="text-[#fff] drop-shadow-text font-[700]"
          style={{ fontSize: "clamp(2rem, 3vw, 4rem)" }}>
          {t("Our Products")}
        </h1>
        <div className="flex flex-col gap-4 items-center">
          <span data-aos="fade-left">
            <img src={o} alt="" className="w-[1.5rem] h-[1.5rem]" />
          </span>
          <span data-aos="fade-left">
            <img src={th} alt="" className="w-[1.5rem] h-[1.5rem]" />
          </span>
          <span data-aos="fade-left">
            <img src={fi} alt="" className="w-[1.5rem] h-[1.5rem]" />
          </span>
        </div>
      </div>
    </div>
  );
}

export default Banner;
