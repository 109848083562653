import React, { useEffect, useState } from "react";
import arrow from "../../../Assets/Images/Icon.svg";
import { Link } from "react-router-dom";
import left from "../../../Assets/Images/Arrow-l.svg";
import right from "../../../Assets/Images/Arrow-r.svg";
import Aos from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { initialLanguage } from "../../../Consts";

function News({ isHome }) {
  const { t } = useTranslation();

  const responsive = {
    largeDesktop: {
      breakpoint: { max: 4000, min: 1600 }, // Screens larger than 1600px
      items: 5, // Show 6 items on large desktop screens
    },
    desktop: {
      breakpoint: { max: 1600, min: 1200 }, // Screens between 1200px and 1600px
      items: 4, // Show 5 items on desktop screens
    },
    tablet: {
      breakpoint: { max: 1199, min: 768 }, // Screens between 768px and 1199px
      items: 3, // Show 3 items on tablet screens
    },
    smallTablet: {
      breakpoint: { max: 1024, min: 768 }, // More specific tablet range (768px to 1024px)
      items: 3, // Show 2 items on small tablets
    },
    mobile: {
      breakpoint: { max: 768, min: 576 }, // Screens between 576px and 768px
      items: 3, // Show 2 items on mobile screens (larger than 576px but smaller than 768px)
    },
    smallMobile: {
      breakpoint: { max: 575, min: 0 }, // Screens smaller than 576px
      items: 1, // Show 1 item on small mobile screens
    },
  };

  return (
    <div>
      {
        isHome.length > 0 ? (
          <div>
            <div id="slider" dir="ltr">
              <Carousel
                responsive={responsive}
                infinite
                autoPlay
                autoPlaySpeed={3000}
                showDots={false}
                arrows
                containerClass="carousel-container relative z-0"
                itemClass="px-3" // Adds spacing between items
              >
                {isHome.map((item) => (
                  <div
                    key={item.id}
                    className="flex justify-between item-start gap-[2rem] lg:gap-10 overflow-hidden"
                    dir="auto"
                  >
                    <div
                      data-aos="fade-left"
                      data-aos-duration="1000"
                      data-aos-easing="linear"
                      data-aos-delay="500"
                      className="relative group flex flex-col items-start gap-[15px] w-full lg:w-[25vw]"
                    >
                      <div className="image-container rounded-md">
                        <Link to={"/news/" + item.id}>
                          <img
                            src={item.image_url}
                            alt=""
                            className="w-[100vw] h-[75vw] md:w-[50vw] md:h-[25vw] lg:w-[50vw] lg:h-[22vw] xl:w-[50vw] xl:h-[16vw] rounded-md object-cover"
                          />
                        </Link>
                      </div>
                      <h1 className="text-[#212121]"
                        style={{ fontSize: "clamp(1rem, .9vw, 4rem)" }}>
                        {item.date}
                      </h1>
                      <p className="text-start text-[#212121] font-[600]"
                        style={{ fontSize: "clamp(.9rem, .9vw, 4rem)" }}>
                        {item.title}
                      </p>
                      <Link
                        to={"/news/1"}
                        className="flex gap-[.5rem] items-center text-[#0A66B0] font-[600]"
                        style={{ fontSize: "clamp(1rem, 1vw, 4rem)" }}
                      >
                        {t("readMore")}
                        <img
                          src={arrow}
                          alt=""
                          className={initialLanguage == "ar" ? "flip-horizontal w-[1rem] h-[1rem]" : "w-[1rem] h-[1rem]"}
                        />
                      </Link>
                    </div>
                  </div>
                ))}
              </Carousel>

            </div>
          </div>
        ) : (
          <div></div>
        )
      }
    </div>
  );
}

export default News;
