import React, { useState, useEffect } from "react";
import ar from "../../Assets/Images/ar.png";
import en from "../../Assets/Images/en.png";
import logo from "../../Assets/Images/logo.png";
import Select from "react-select";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

function NavBar() {
  const { t, i18n } = useTranslation();
  const storedLanguage = localStorage.getItem("language");
  const initialLanguage = storedLanguage || "en";

  useEffect(() => {
    i18n.init({
      backend: {
        loadPath: `/locales/${initialLanguage}/translation.json`,
      },
      lng: initialLanguage,
      fallbackLng: "en",
      interpolation: {
        escapeValue: false, // Not needed for React
      },
    });
    forceRerender();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [initialLanguage, i18n.language]);

  const languageOptions = [
    {
      value: "en",
      label: (
        <img
          src={en}
          alt="en"
          className="w-[2.25rem] h-[2.25rem] rounded-[50%] border-2 m-auto"
        />
      ),
    },
    {
      value: "ar",
      label: (
        <img
          src={ar}
          alt="ar"
          className="w-[2.25rem] h-[2.25rem] rounded-[50%] border-2 m-auto"
        />
      ),
    },
  ];
  const navData = [
    { name: t("Home"), link: "" },
    { name: t("About Us"), link: "/about" },
    { name: t("Services"), link: "/services" },
    { name: t("Projects"), link: "/projects" },
    { name: t("Products"), link: "/products" },
    { name: t("News"), link: "/news" },
    { name: t("Careers"), link: "/careers" },
    { name: t("Contact Us"), link: "/contact" },
  ];

  const [selectedLanguage, setSelectedLanguage] = useState(
    initialLanguage === "en" ? languageOptions[0] : languageOptions[1]
  );
  document.body.dir = initialLanguage === "en" ? "ltr" : "rtl";
  const [isScrolled, setIsScrolled] = useState(false);
  const [, setForceUpdate] = useState(false);
  const handleLanguageChange = (selectedOption) => {
    const selectedLanguage = selectedOption.value;
    localStorage.setItem("language", selectedLanguage);

    if (selectedLanguage === "en") {
      document.body.dir = "rtl";
    } else {
      document.body.dir = "ltr";
    }
    setSelectedLanguage(selectedOption);
    i18n.changeLanguage(selectedLanguage);
    window.location.reload(false);
  };

  const handleScroll = () => {
    const position = window.scrollY;
    setIsScrolled(position > window.innerHeight * 0.01);
  };

  const forceRerender = () => {
    setForceUpdate((prev) => !prev);
  };

  return (
    <nav
      id="sidebar"
      className={`relative z-10 ${isScrolled ? "scrolled" : ""}`}
    >
      {/* nav hidden lg:flex container items-center justify-between h-[155px] w-[1153px]  2xl:w-[1330px] xl:w-[1055px] lg:w-[1020px] lg:h-[85px] md:w-[700px] sm:w-[700px] font-po mx-auto" */}
      <div className="fixed top-0 left-0 right-0 bg-[#FFFFFF80] shadow-lg">
        <div className="nav hidden lg:flex text-center justify-between mx-[2rem] lg:mx-[7rem] md:mx-[4rem]">
          <div className="logo">
            <Link to={""}>
              <img
                src={logo}
                alt="Logo"
                className="w-[10vw] h-[10vh] object-contain"
              />
            </Link>
          </div>
          <div className="flex items-center">
            {navData.map((link) => (
              <NavLink
                to={link.link}
                key={link.link}
                className={
                  "text-black border-r-[1px] border-[#C6CDD5] px-2 font-[400] not-italic relative inline-block"
                }
                style={{ fontSize: "clamp(.9rem, .9vw, 4rem)" }}
              >
                {link.name}
              </NavLink>
            ))}

            <div className="select-container">
              <Select
                value={selectedLanguage}
                onChange={handleLanguageChange}
                options={languageOptions}
                isSearchable={false}
                hideSelectedOptions={true}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    border: "none",
                    width: "90px",
                    color: "#000",
                    backgroundColor: "transparent",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    overflow: "visible",
                  }),
                  option: (provided) => ({
                    ...provided,
                    display: "flex",
                    alignItems: "center",
                    padding: "5px",
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    display: "flex",
                    alignItems: "center",
                  }),
                  dropdownIndicator: (provided) => ({
                    ...provided,
                    color: "#000", // Change color here
                  }),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
