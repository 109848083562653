import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useProjects } from "../../Context/ProjectsContext";
import { useTranslation } from "react-i18next";

function Cards() {
  const { t } = useTranslation();

  const {
    getProjects,
    isProjects,
    getProjectsCategories,
    isProjectsCategories,
  } = useProjects();
  const [selectedOption, setSelectedOption] = useState("All");
  const [selectedLanguage, setSelectedLanguage] = useState();

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  useEffect(() => {
    setSelectedOption("All");
    getProjects();
    getProjectsCategories();
    toggleLanguage();
  }, []);

  const toggleLanguage = () => {
    const newLanguage = localStorage.getItem("language");
    setSelectedLanguage(newLanguage);
  };

  return (
    <div className="flex flex-col gap-12 my-10 w-[100%]">
      <div className="flex gap-[0.8rem] justify-center items-center lg:gap-10"
        data-aos="fade-in"
        data-aos-easing="linear"
        data-aos-duration="1000"
        data-aos-delay="500"
        style={{ fontSize: "clamp(1.1rem, 1.1vw, 4rem)" }}>
        <button
          onClick={() => handleOptionChange("All")}
          className={
            selectedOption === "All"
              ? "active text-[#0A66B0] font-[600]"
              : "font-[600]"
          }
          key="All"

        >
          {t("all")}
        </button>
        {isProjectsCategories?.data?.map((category) => (
          <button
            onClick={() => handleOptionChange(category.id)}
            className={
              selectedOption === category.id
                ? "active text-[#0A66B0] font-[600]"
                : "font-[600]"
            }
            key={category.id}
          >
            {category.name}
          </button>
        ))}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mt-10">
        {isProjects?.data?.map(
          (item, index) =>
            (selectedOption === "All" ||
              item.project_cat_id === selectedOption) && (
              <Link
                data-aos="fade-in"
                data-aos-easing="linear"
                data-aos-duration="1000"
                data-aos-delay="500"
                to={"/project/" + item.id}
                key={index}
              >
                <div className="relative group flex flex-col items-start gap-[15px] hover-overlay rounded-md">
                  <img
                    src={item.image_url}
                    alt=""
                    className="w-[100vw] h-[75vw] sm:w-[30vw] sm:h-[30vw] md:w-[50vw] md:h-[35vw] lg:w-[50vw] lg:h-[22vw] xl:w-[50vw] xl:h-[20vw] rounded-md object-cover"
                  />
                  <div className=" absolute bottom-[4rem] 2xl:bottom-[5rem] left-[.5rem] right-0 flex items-center justify-start opacity-0 group-hover:opacity-100 transition-opacity">
                    <div
                      className={` ${selectedLanguage === "ar"
                        ? "absolute right-[2rem] border-r-2 text-direction-arabic "
                        : "absolute left-[1rem] border-l-2 text-direction-english "
                        }`}
                    >
                      <p className="text-start border-b-2 text-[#FFF] font-[600] ps-3 py-3 "
                        style={{ fontSize: "clamp(.9rem, .9vw, 4rem)" }}>
                        {item.title}
                      </p>
                      <p className="text-start text-[13px] lg:text-[15px] text-[#319AEF] font-[400] ps-3 py-3"
                        style={{ fontSize: "clamp(.8rem, .8vw, 4rem)" }}>
                        {item.project_owner}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            )
        )}
      </div>
    </div>
  );
}

export default Cards;
