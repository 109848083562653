import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useProducts } from "../../Context/ProductsContext";
import { useTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function SectionThree() {
  const { t } = useTranslation();
  const { getProductsBrands, isProductsBrands } = useProducts();

  useEffect(() => {
    getProductsBrands();
  }, []);

  const responsive = {
    desktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
    },
  };


  return (
    <div className="flex flex-col gap-[1rem] w-full mb-10">
      {isProductsBrands?.data?.length > 0 ?
        <div>
          <div className="flex justify-between items-center"
            data-aos="fade-in"
            data-aos-duration="1000"
            data-aos-easing="linear"
            data-aos-delay="500">
            <h1
              className="text-[#0A66B0] font-semibold"
              style={{ fontSize: "clamp(2rem, 2.25vw, 4rem)" }}
            >
              {t("Brands")}
            </h1>
          </div>
          {isProductsBrands?.data?.length > 1 ? (
            <Carousel
              responsive={responsive}
              infinite
              autoPlay
              autoPlaySpeed={3000}
              showDots={false}
              arrows
              containerClass="carousel-container"
              className="flex lg:justify-center"
            >
              {isProductsBrands?.data?.map((brand) => (
                <Link to={"/brand/" + brand.id} key={brand.id}>
                  <div className="flex flex-col gap-5 items-center cursor-pointer"
                    data-aos="fade-left"
                    data-aos-duration="1000"
                    data-aos-easing="linear"
                    data-aos-delay="500">
                    <img
                      src={brand.image_url}
                      alt={brand.name}
                      className="w-[250px] h-[250px] lg:w-[300px] lg:h-[300px] rounded-md"
                    />
                  </div>
                </Link>
              ))}
            </Carousel>
          ) : (
            <div>
              {isProductsBrands?.data?.map((brand) => (
                <Link to={"/brand/" + brand.id} key={brand.id}>
                  <div className="flex flex-col gap-5 items-center cursor-pointer"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-easing="linear"
                    data-aos-delay="500">
                    <img
                      src={brand.image_url}
                      alt={brand.name}
                      className="w-full h-[200px] md:h-[250px] lg:h-[300px] xl:h-[350px] 2xl:h-[400px] rounded-md"
                    />
                  </div>
                </Link>
              ))}
            </div>
          )}
        </div> : <div></div>}
    </div>
  );
}

export default SectionThree;
