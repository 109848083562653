import React, { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { initialLanguage } from "../../../Consts";

function HomeCarousel({ isHome }) {
  const responsive = {
    desktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Carousel
      responsive={responsive}
      infinite={isHome.length > 1}
      autoPlay={true}
      autoPlaySpeed={3000}
      containerClass="carousel-container relative z-0"
      itemClass="carousel-item"
      arrows={true}
      removeArrowOnDeviceType={["tablet", "mobile"]}
      lazyLoad={true}
      pauseOnHover={false}
    >
      {isHome.map((item, index) => (
        <div key={index} className="relative w-full h-[60vh] lg:h-[100vh]">
          {/* Background Image with Overlay */}
          <div
            data-aos="fade-in"
            data-aos-duration="1000"
            data-aos-easing="linear"
            data-aos-delay="500"
            className={initialLanguage == "ar" ? "flip-horizontal absolute inset-0 bg-cover bg-center bg-no-repeat" : "absolute inset-0 bg-cover bg-center bg-no-repeat"}
            style={{
              backgroundImage: `url(${item?.data?.image_url})`,
            }}
          >
            <div className="absolute inset-0 bg-black bg-opacity-40"></div>
          </div>

          {/* Text Content */}
          <div
            className="absolute inset-y-0 left-0 flex flex-col justify-center text-white px-4 lg:px-[7rem] w-[90%]"
          >
            <h1
              data-aos="fade-left"
              data-aos-duration="1000"
              data-aos-easing="linear"
              data-aos-delay="500"
              className="text-start font-[700] pb-5 leading-[100%]"
              style={{ fontSize: "clamp(3rem, 4.5vw, 10rem)" }}
              dangerouslySetInnerHTML={{
                __html: item.data.title.replace(/\n/g, "<br />"),
              }}
            >
            </h1>
            <p
              data-aos="fade-left"
              data-aos-duration="1000"
              data-aos-easing="linear"
              data-aos-delay="500"
              className="text-start font-[400]"
              style={{ fontSize: "clamp(1.5rem, 1.5vw, 8rem)" }}
              dangerouslySetInnerHTML={{
                __html: item.data.excerpt.replace(/\n/g, "<br />"),
              }}
            >
            </p>
          </div>

        </div>
      ))}
    </Carousel>
  );
}

export default HomeCarousel;
