import axios from "axios";
import { createContext, useContext, useState } from "react";
import { newBaseUrl } from "../Consts"

export const PagesBannersContext = createContext();
export const usePagesBanners = () => {
    return useContext(PagesBannersContext);
};

export default function PagesBannersContextProvider({ children }) {
    const [isBanner, setIsBanner] = useState()

    const headers = {
        'Content-Type': 'application/json',
    };

    async function getBanner(id) {
        setIsBanner();
        try {
            const res = await axios.get(`${newBaseUrl}/api/page-banner`, { headers });
            setIsBanner(res?.data);
            console.log(res?.data?.data);
        } catch (err) {
            // console.error(err);
        }
    }
    return (
        <PagesBannersContext.Provider value={{
            getBanner, isBanner
        }}>
            {children}
        </PagesBannersContext.Provider>
    );
}
