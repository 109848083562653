import React from "react";
import { useTranslation } from "react-i18next";

function Cards({ isContact }) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col lg:flex-row justify-center gap-10 my-10 w-full" style={{ fontSize: "clamp(1rem, 1vw, 4rem)" }}>
      <div className="flex flex-col shadow-custom border-t-[10px] border-[#0A66B0] px-6 py-10 lg:w-[50vw] rounded-md "
        data-aos="fade-in"
        data-aos-easing="linear"
        data-aos-duration="1000"
        data-aos-delay="500">
        <div className="flex flex-col items-start gap-3">
          <h1 className="text-[#000] text-start font-[600]" style={{ fontSize: "clamp(1.2rem, 1.2vw, 4rem)" }}>
            {isContact?.[0].name}
          </h1>
          <h3 className="text-[#141414] text-start font-[400]">
            {isContact?.[0].address}
          </h3>
          <h3 className="text-[#141414] text-start font-[400]">
            {isContact?.[0].tel}
          </h3>
          <h3 className="text-[#141414] text-start font-[400]">
            {isContact?.[0].mail}
          </h3>
        </div>
        <button className="text-[#0A66B0] text-[14px] lg:text-[20px] font-[400] rounded-md block mx-auto mt-14 mb-5 py-1 px-10 border-[2px] border-[#0A66B0] hover:bg-[#0A66B0] hover:text-white">
          <a href={isContact?.[0].directions} target="_blank" rel="noopener noreferrer">
            {t("GetDirections")}
          </a>
        </button>
      </div>

      <div className="flex flex-col shadow-custom border-t-[10px] border-[#AF1F24] px-6 py-10 lg:w-[50vw] rounded-md "
        data-aos="fade-in"
        data-aos-easing="linear"
        data-aos-duration="1000"
        data-aos-delay="500">
        <div className="flex flex-col items-start gap-3">
          <h1 className="text-[#000] text-start font-[600]" style={{ fontSize: "clamp(1.2rem, 1.2vw, 4rem)" }}>
            {isContact?.[1].name}
          </h1>
          <h3 className="text-[#141414] text-start font-[400]">
            {isContact?.[1].address}
          </h3>
          <h3 className="text-[#141414] text-start font-[400]">
            {isContact?.[1].tel}
          </h3>
          <h3 className="text-[#141414] text-start font-[400]">
            {isContact?.[1].mail}
          </h3>
        </div>
        <button className="text-[#AF1F24] text-[14px] lg:text-[20px] font-[400] rounded-md block mx-auto mt-14 mb-5 py-1 px-10 border-[2px] border-[#AF1F24] hover:bg-[#AF1F24] hover:text-white">
          <a href={isContact?.[1].directions} target="_blank" rel="noopener noreferrer">
            {t("GetDirections")}
          </a>
        </button>
      </div>

    </div>
  );
}

export default Cards;
