import React, { useEffect } from "react";
import SectionOne from "../Components/New/SectionOne";
import SectionThree from "../Components/New/SectionThree";
import SectionTwo from "../Components/New/SectionTwo";
import { useNews } from "../Context/NewsContext";
import { useParams } from "react-router-dom";

function New() {
  const { id } = useParams();
  const { getNewsDetails, isNewsDetails } = useNews();

  useEffect(() => {
    getNewsDetails(id);
    window.scrollTo({ top: 0, behavior: "auto" });
  }, [id]);

  return (
    <div className="overlay">
      <div className="flex flex-col mx-[2rem] lg:mx-[7rem] md:mx-[4rem] items-center gap-7">
        <SectionOne isNewsDetails={isNewsDetails?.data?.main_Post} />
        <SectionTwo isNewsDetails={isNewsDetails?.data?.main_Post?.content} />
      </div>
      {isNewsDetails?.data?.related_News.length > 0 ?
        <SectionThree isNewsDetails={isNewsDetails?.data?.related_News} />
        : <div></div>}
    </div>
  );
}

export default New;
