import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function SectionOne({ isProject }) {
  const [mainImage, setMainImage] = useState("");
  const [sliderOpen, setSliderOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [galleryImages, setGalleryImages] = useState([]);
  const { t } = useTranslation();
  const [visibleThumbnailsStart, setVisibleThumbnailsStart] = useState(0);

  useEffect(() => {
    if (isProject) {
      const mainImageObject = { image_url: isProject.image_url };
      const gallery = isProject.gallery || [];
      const updatedGallery = [mainImageObject, ...gallery];

      setMainImage(mainImageObject.image_url);
      setGalleryImages(updatedGallery);
    }
  }, [isProject]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const sliderContent = document.getElementById("slider-content");
      if (sliderOpen && sliderContent && !sliderContent.contains(event.target)) {
        setSliderOpen(false);
      }
    };

    const handleKeyPress = (event) => {
      if (event.key === "Escape") {
        setSliderOpen(false);
      }
    };

    if (sliderOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleKeyPress);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [sliderOpen]);

  useEffect(() => {
    let interval;
    if (galleryImages.length > 1 && !sliderOpen) {
      interval = setInterval(() => {
        handleNextImage();
      }, 3000); // Change slides every 3 seconds
    }
  
    return () => {
      if (interval) {
        clearInterval(interval); // Cleanup on slider open or component unmount
      }
    };
  }, [selectedImageIndex, galleryImages, sliderOpen]);
  

  const handleThumbnailClick = (index) => {
    setSelectedImageIndex(index);
    setMainImage(galleryImages[index].image_url);
  };

  const handlePrevImage = () => {
    if (selectedImageIndex > 0) {
      const newIndex = selectedImageIndex - 1;
      setSelectedImageIndex(newIndex);
      setMainImage(galleryImages[newIndex].image_url);
    }
    if (visibleThumbnailsStart > 0) {
      setVisibleThumbnailsStart(visibleThumbnailsStart - 1);
    }
  };

  const handleNextImage = () => {
    if (selectedImageIndex < galleryImages.length - 1) {
      const newIndex = selectedImageIndex + 1;
      setSelectedImageIndex(newIndex);
      setMainImage(galleryImages[newIndex].image_url);
    } else {
      // Loop back to the first image
      setSelectedImageIndex(0);
      setMainImage(galleryImages[0].image_url);
    }
    if (visibleThumbnailsStart + 4 < galleryImages.length) {
      setVisibleThumbnailsStart(visibleThumbnailsStart + 1);
    } else {
      // Reset visible thumbnails when looping back
      setVisibleThumbnailsStart(0);
    }
  };

  return (
    <div className="flex flex-col lg:flex-row mt-[10rem] lg:items-start items-start gap-5 lg:gap-10 w-full">
      <div
        data-aos="fade-in"
        data-aos-duration="1000"
        data-aos-easing="linear"
        data-aos-delay="500"
        className="flex flex-col-reverse lg:flex-row items-center gap-4 lg:gap-7"
      >
        {galleryImages.length > 1 ? (
          <div className="relative flex flex-row lg:flex-col items-center justify-center gap-3 w-full lg:w-auto">
            <div className="flex flex-row lg:flex-col items-center gap-2 overflow-x-auto lg:overflow-y-auto w-full lg:w-auto">
              {galleryImages
                .slice(visibleThumbnailsStart, visibleThumbnailsStart + 4)
                .map((image, index) => (
                  <img
                    key={index}
                    src={image.image_url}
                    alt="Thumbnail"
                    className={`w-[15vw] h-[15vw] sm:w-[15vw] sm:h-[15vw] md:w-[15vw] md:h-[15vw] lg:w-[5vw] lg:h-[5vw] cursor-pointer rounded-md ${
                      selectedImageIndex === visibleThumbnailsStart + index
                        ? "border-2 border-blue-500"
                        : ""
                    }`}
                    onClick={() => handleThumbnailClick(visibleThumbnailsStart + index)}
                  />
                ))}
            </div>
          </div>
        ) : (
          <div></div>
        )}
        <div className="flex justify-center w-full">
          <img
            src={mainImage}
            alt="Selected"
            className="w-[100vw] h-[70vw] md:w-[100vw] md:h-[70vw] lg:w-[65vw] lg:h-[30vw] xl:max-h-[25vw] xl:w-[40vw] rounded-md object-cover cursor-pointer"
            onClick={() => setSliderOpen(true)}
          />
        </div>
      </div>
      <div data-aos="fade-left"
        data-aos-duration="1000"
        data-aos-easing="linear"
        data-aos-delay="500" className="flex flex-col text-start gap-[1rem] lg:gap-6 lg:w-[60vw]"
        style={{ fontSize: "clamp(1.1rem, 1.1vw, 4rem)" }}>
        <h1 className="text-[#0A66B0] font-[700]"
          style={{ fontSize: "clamp(1.6rem, 1.6vw, 4rem)" }}>{isProject?.title}</h1>
        <p className="text-start text-[#141414] font-[400]">
          {isProject?.excerpt}</p>
        <span className="border"></span>
        <p className="text-start text-[#141414] font-[400]">
          <span className="text-[#5F5F5F]">{t("projectOwner")}</span>{isProject?.project_owner}
        </p>
        <p className="text-start text-[#141414] font-[400]">
          <span className="text-[#5F5F5F]">{t("area")}</span>{isProject?.area}
        </p>
        <p className="text-start text-[#141414] font-[400]">
          <span className="text-[#5F5F5F]">{t("year")}</span>{isProject?.year}
        </p>
      </div>


      {sliderOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-80 z-50 flex justify-center items-center">
          <div
            id="slider-content"
            className="relative flex items-center justify-center"
            onClick={(e) => e.stopPropagation()}
          >
            <span
              className="absolute top-2 right-2 text-white cursor-pointer text-2xl"
              onClick={() => setSliderOpen(false)}
            >
              &#10005;
            </span>

            {selectedImageIndex > 0 && (
              <span
                className="absolute left-4 text-white cursor-pointer text-4xl"
                onClick={handlePrevImage}
              >
                &#10094;
              </span>
            )}

            <img
              id="slider-image"
              src={galleryImages[selectedImageIndex]?.image_url}
              alt="Slider"
              className="max-h-[90vh] max-w-[90vw] mx-auto rounded-md"
              onClick={(e) => e.stopPropagation()}
            />

            {selectedImageIndex < galleryImages.length - 1 && (
              <span
                className="absolute right-4 text-white cursor-pointer text-4xl"
                onClick={handleNextImage}
              >
                &#10095;
              </span>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default SectionOne;
