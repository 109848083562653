import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useServices } from "../../Context/ServicesContext";

function Cards() {
  const [hoveredService, setHoveredService] = useState(null);
  const { getServicesCategory, isServicesCategory } = useServices();

  useEffect(() => {
    getServicesCategory();
  }, []);


  const handleMouseEnter = (serviceId) => {
    setHoveredService(serviceId);
  };

  const handleMouseLeave = () => {
    setHoveredService(null);
  };

  const handleTouchStart = (serviceId) => {
    // Simulate hover effect on touch devices
    setHoveredService(serviceId);
  };

  const handleTouchEnd = () => {
    // Remove hover effect on touch devices
    setHoveredService(null);
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mt-10">
      {isServicesCategory?.data?.map((service, index) => (
        <Link
          data-aos="fade-in"
          data-aos-easing="linear"
          data-aos-duration="1000"
          data-aos-delay="500"
          to={"/service/" + service.id}
          onTouchStart={() => handleTouchStart(service.id)}
          onTouchEnd={handleTouchEnd}
          key={index}
        >
          <div
            className="relative group cursor-pointer"
            onMouseEnter={() => handleMouseEnter(service.id)}
            onMouseLeave={handleMouseLeave}
          >
            <img
              src={service.image_url}
              alt={service.name}
              className="w-[100vw] h-[75vw] sm:w-[30vw] sm:h-[30vw] md:w-[50vw] md:h-[35vw] lg:w-[50vw] lg:h-[22vw] xl:w-[50vw] xl:h-[20vw] rounded-md"
            />
            <div
              className={`absolute inset-x-0 bottom-0 xl:h-[5vw] lg:h-[6vw] h-[14vw] md:h-[8vw] grid place-items-center bg-[#00000080] rounded-b-md ${hoveredService === service.id ? "opacity-0" : "opacity-100"
                } transition-opacity`}
            >
              <h1 className="text-white font-[600]" style={{ fontSize: "clamp(1.2rem, 1.2vw, 4rem)" }}>
                {service.name}
              </h1>
            </div>
            <div
              className={`absolute inset-0 grid place-items-center bg-[#00000080] p-6 rounded-md ${hoveredService === service.id ? "opacity-100" : "opacity-0"
                } transition-opacity`}
            >
              <h1
                data-aos="fade-up"
                data-aos-duration="3000"
                className="text-center text-white font-[600] p-4 border-2 border-[#0966AE] rounded-md flex items-center justify-center"
                style={{
                  fontSize: "clamp(.85rem, .85vw, 4rem)",
                  width: "100%",
                  height: "100%",
                }}
              >
                {service.excerpt}
              </h1>
            </div>
          </div>
        </Link>
      ))}
    </div>

  );
}

export default Cards;
