import React from "react";


function SectionTwo({ isNewsDetails }) {

  return (
    <div className="flex flex-col">
      {isNewsDetails?.map((post, index) => (
        <div
          className="flex flex-col items-center lg:flex-row gap-14 my-10"
          key={index}
        >
          {index % 2 ? (
            <>
              <div
                data-aos="fade-right"
                data-aos-easing="linear"
                data-aos-duration="1000"
                data-aos-delay="500"
              >
                <img
                  src={post?.image_url}
                  alt=""
                  className="rounded-md w-[70vw] md:w-[50vw] lg:max-w-[30vw] mx-auto"
                />
              </div>
              <div
                data-aos="fade-left"
                data-aos-easing="linear"
                data-aos-duration="1000"
                data-aos-delay="500"
                className="description flex flex-col gap-5 "
              >
                <p
                  className="text-start text-[#141414]"
                  style={{ fontSize: "clamp(1.2rem, 1.2vw, 4rem)" }}
                  dangerouslySetInnerHTML={{
                    __html: post?.content.replace(/\n/g, "<br />"),
                  }}>
                </p>
              </div>
            </>
          ) : (
            <>
              <div
                data-aos="fade-right"
                data-aos-easing="linear"
                data-aos-duration="1000"
                data-aos-delay="500"
                className="description flex flex-col gap-5 order-1 lg:order-0"
              >
                <p
                  className="text-start text-[#141414]"
                  style={{ fontSize: "clamp(1.2rem, 1.2vw, 4rem)" }}
                  dangerouslySetInnerHTML={{
                    __html: post?.content.replace(/\n/g, "<br />"),
                  }}>
                </p>
              </div>
              <div
                data-aos="fade-left"
                data-aos-easing="linear"
                data-aos-duration="1000"
                data-aos-delay="500"
                className="order-0 lg:order-1"
              >
                <img
                  src={post?.image_url}
                  alt=""
                  className="rounded-md w-[70vw] md:w-[50vw] lg:max-w-[30vw] mx-auto"
                />
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  );
}

export default SectionTwo;
