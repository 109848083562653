import arrow from "../../Assets/Images/Icon.svg";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNews } from "../../Context/NewsContext";
import { useTranslation } from "react-i18next";
import { initialLanguage } from "../../Consts"

function Cards() {
  const { getNews, isNews, getNewsCategories, isNewsCategories } = useNews();
  const { t } = useTranslation();

  useEffect(() => {
    getNews()
    // getNewsCategories();
  }, []);

  return (
    <div className="flex flex-col gap-12 my-10 w-[100%]">
      {/* <div className="flex flex-col items-start gap-3">
        <label
          htmlFor="category"
          className="text-[#141414] text-[15px] lg:text-[20px] font-[600]"
        >
          Year
        </label>
        <select
          id="category"
          className="border-[1px] border-[#DFEAF2] py-1 text-[15px] lg:text-[20px] w-[20rem] px-2"
          style={{ color: "#5F5F5F" }}
        >
          <option>All</option>
          {isNewsCategories?.data?.map((item, index) => (
            <option>{item.name}</option>
          ))}
        </select>
      </div> */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mt-10">
        {isNews?.data?.map((item, index) => (
          <div
            key={item.id}
            className="flex justify-between item-start gap-[2rem] lg:gap-10 overflow-hidden"
            dir="auto"
          >
            <div
              data-aos="fade-in"
              data-aos-duration="1000"
              data-aos-easing="linear"
              data-aos-delay="500"
              className="relative group flex flex-col items-start gap-[15px] w-full lg:w-[25vw]"
            >
              <div className="image-container rounded-md">
                <Link to={"/news/" + item.id}>
                  <img
                    src={item.image_url}
                    alt=""
                    className="w-[100vw] h-[75vw] sm:w-[30vw] sm:h-[30vw] md:w-[50vw] md:h-[35vw] lg:w-[50vw] lg:h-[22vw] xl:w-[50vw] xl:h-[20vw] rounded-md object-cover"
                  />
                </Link>
              </div>
              <h1 className="text-[#212121]"
                style={{ fontSize: "clamp(1rem, .9vw, 4rem)" }}>
                {item.date}
              </h1>
              <p className="text-start text-[#212121] font-[600]"
                style={{ fontSize: "clamp(.9rem, .9vw, 4rem)" }}>
                {item.title}
              </p>
              <Link
                to={"/news/1"}
                className="flex gap-1 lg:gap-[0.2rem] items-center text-[#0A66B0] font-[600]"
                style={{ fontSize: "clamp(1rem, 1vw, 4rem)" }}
              >
                {t("readMore")}
                <img
                  src={arrow}
                  alt=""
                  className={initialLanguage == "ar" ? "flip-horizontal w-[2vw] lg:h-[1vh]" : "w-[2vw] lg:h-[1vh]"}
                />
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Cards;
