import React, { useEffect } from "react";
import banner from "../../Assets/Images/banner.png";
import "react-multi-carousel/lib/styles.css";
import { useTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";

function Partners({ isHome }) {
  const { t } = useTranslation();

  const responsive = {
    desktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 5,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 3,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 2,
      slidesToSlide: 1,
    },
  };

  return (
    <div className="relative">
      <div
        className="py-5"
        style={{
          backgroundImage: `url(${banner})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="lg:banner lg:banner1 flex-col items-center mx-[2rem] lg:mx-[7rem] md:mx-[4rem]">
          {/* Title */}
          <h1
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-delay="500"
            data-aos-offset="0"
            className="relative text-[#AF1F24] text-[25px] lg:text-[50px] font-[400] leading-[120%]"
            style={{ fontSize: "clamp(2rem, 2.25vw, 4rem)" }}
          >
            {t("OURPARTNERS")}
          </h1>

          {/* Carousel */}
          <div
            data-aos="fade-left"
            data-aos-delay="2000"
            className="justify-center lg:flex-row lg:mx-0 gap-[2rem] lg:gap-[2rem] lg:my-5 overflow-hidden"
            dir="ltr"
          >
            <Carousel
              responsive={responsive}
              infinite={isHome.length > 1}
              autoPlay={true}
              autoPlaySpeed={2000}
              containerClass="carousel-container"
              itemClass="carousel-item"
              arrows={false}
            >
              {isHome?.map((data, index) => (
                <img
                  key={`partner-${index}`}
                  src={data.image_url}
                  className="w-[150px] h-[150px] lg:w-[210px] lg:h-[150px] 2xl:h-[170px] object-contain mx-auto"
                  alt={`Partner ${index + 1}`}
                />
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Partners;
