import React, { useEffect } from "react";
import Services from "./Services";
import "aos/dist/aos.css";
import { t } from "i18next";

function InfoServices({ isHome }) {
  return (
    <div className="bg-[#F1F1F180] pb-20">
      <div className="carousel mx-[2rem] lg:mx-[7rem] md:mx-[4rem]">
        <div className="flex justify-between items-end py-10">
          <div className="relative lg:news-title flex flex-col items-start ">
            <h1 className="text-[#AF1F24] text-[15px] lg:text-[24px] font-[400]"
              style={{ fontSize: "clamp(1.6rem, 1.6vw, 4rem)" }}>
              {t("WHAT WE DO")}
            </h1>
            <h1 className="text-[#0A66B0] text-[25px] lg:text-[50px] font-[600]"
              style={{ fontSize: "clamp(2rem, 2.25vw, 4rem)" }}>
              {t("Our Services")}
            </h1>
          </div>
        </div>
        <main>
          <Services isHome={isHome} />
        </main>
      </div>
    </div>
  );
}

export default InfoServices;
