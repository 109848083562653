import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";
import { Link } from "react-router-dom";

function Services({ isHome }) {
  const { t } = useTranslation();
  const [hoveredService, setHoveredService] = useState(null);

  const responsive = {
    largeDesktop: {
      breakpoint: { max: 4000, min: 1600 }, // Screens larger than 1600px
      items: 5, // Show 6 items on large desktop screens
    },
    desktop: {
      breakpoint: { max: 1600, min: 1200 }, // Screens between 1200px and 1600px
      items: 4, // Show 5 items on desktop screens
    },
    tablet: {
      breakpoint: { max: 1199, min: 768 }, // Screens between 768px and 1199px
      items: 3, // Show 3 items on tablet screens
    },
    smallTablet: {
      breakpoint: { max: 1024, min: 768 }, // More specific tablet range (768px to 1024px)
      items: 3, // Show 2 items on small tablets
    },
    mobile: {
      breakpoint: { max: 768, min: 576 }, // Screens between 576px and 768px
      items: 3, // Show 2 items on mobile screens (larger than 576px but smaller than 768px)
    },
    smallMobile: {
      breakpoint: { max: 575, min: 0 }, // Screens smaller than 576px
      items: 1, // Show 1 item on small mobile screens
    },
  };

  const handleMouseEnter = (serviceId) => {
    setHoveredService(serviceId);
  };

  const handleMouseLeave = () => {
    setHoveredService(null);
  };

  const handleTouchStart = (serviceId) => {
    // Simulate hover effect on touch devices
    setHoveredService(serviceId);
  };

  const handleTouchEnd = () => {
    // Remove hover effect on touch devices
    setHoveredService(null);
  };

  return (
    <div>
      {isHome?.length > 0 ? (
        <Carousel
          responsive={responsive}
          infinite
          autoPlay
          autoPlaySpeed={3000}
          showDots={false}
          arrows
          containerClass="carousel-container relative z-0"
          itemClass="px-3" // Adds spacing between items
        >
          {isHome?.map((item, index) => (
            <Link
              data-aos="fade-in"
              data-aos-easing="linear"
              data-aos-duration="1000"
              data-aos-delay="500"
              to={"/service/" + item.id}
              onTouchStart={() => handleTouchStart(item.id)}
              onTouchEnd={handleTouchEnd}
              key={index}
            >
              <div
                className="relative group cursor-pointer"
                onMouseEnter={() => handleMouseEnter(item.id)}
                onMouseLeave={handleMouseLeave}
              >
                <img
                  src={item.image_url}
                  alt={item.name}
                  className="w-[100vw] h-[75vw] md:w-[50vw] md:h-[25vw] lg:w-[50vw] lg:h-[22vw] xl:w-[50vw] xl:h-[16vw] rounded-md"
                />
                <div
                  className={`absolute inset-x-0 bottom-0 xl:h-[4vw] lg:h-[5vw] h-[14vw] md:h-[6vw] grid place-items-center bg-[#00000080] rounded-b-md ${hoveredService === item.id ? "opacity-0" : "opacity-100"
                    } transition-opacity`}
                >
                  <h1 className="text-white font-[600]" style={{ fontSize: "clamp(1.2rem, 1.2vw, 4rem)" }}>
                    {item.name}
                  </h1>
                </div>
                <div
                  className={`absolute inset-0 grid place-items-center bg-[#00000080] p-6 rounded-md ${hoveredService === item.id ? "opacity-100" : "opacity-0"
                    } transition-opacity`}
                >
                  <h1
                    data-aos="fade-up"
                    data-aos-duration="3000"
                    className="text-center text-white font-[600] p-4 border-2 border-[#0966AE] rounded-md flex items-center justify-center"
                    style={{
                      fontSize: "clamp(.7rem, .7vw, 4rem)",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    {item.excerpt}
                  </h1>
                </div>
              </div>
            </Link>
          ))}
        </Carousel>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default Services;
