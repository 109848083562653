import React, { useEffect, useState } from "react";
import Img from "../Components/Home/Carousel/Img";
import Partners from "../Components/Home/Partners";
import InfoNews from "../Components/Home/News/InfoNews";
import InfoProject from "../Components/Home/FeaturedProject/InfoProject";
import InfoServices from "../Components/Home/Services/InfoServices";
import LatestProducts from "../Components/Home/LatestProducts";
import AboutSection from "../Components/Home/AboutSection";
import { useHome } from "../Context/HomeContext";
function Home() {
  const { getHome, isHome } = useHome("");

  useEffect(() => {
    getHome();
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <div>
      {isHome == null ? (
        <div></div>
      ) :
        <div className="overlay">
          <Img isHome={isHome?.data?.banners} />
          <div className="flex flex-col mx-[2rem] lg:mx-[7rem] md:mx-[4rem] items-center gap-7">
            <AboutSection isHome={isHome?.data?.about} />
          </div>
          <LatestProducts />
          <InfoServices isHome={isHome?.data?.services} />
          <InfoProject isHome={isHome?.data?.projects} />
          <div className="block">
            <InfoNews isHome={isHome?.data?.news} />
          </div>
          <Partners isHome={isHome?.data?.partners} />
        </div>}
    </div>
  );
}

export default Home;
