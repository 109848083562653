import React, { useEffect, useState } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

function SectionOne({ isAbout }) {

  return (
    <div className="flex flex-col items-center justify-between lg:flex-row gap-14 my-5 pt-5">
      <div className="flex flex-col gap-5 order-1 lg:order-0">
        <div
          data-aos="fade-right"
          data-aos-duration="1000"
          data-aos-easing="linear"
          data-aos-delay="500"
          className="flex flex-col items-start gap-5 lg:max-w-[60vw]"
        >
          <p className="text-start text-[#141414] font-[400]"
            style={{ fontSize: "clamp(1.1rem, 1.1vw, 4rem)" }}
          >
            {isAbout?.excerpt != null ? isAbout?.excerpt : null}
          </p>
          {isAbout?.excerpt != null ? <span className="border"></span> : null}
          <p
            className="text-start text-[#141414]"
            style={{ fontSize: "clamp(1.1rem, 1.1vw, 4rem)" }}
            dangerouslySetInnerHTML={{
              __html: isAbout?.description.replace(/\n/g, "<br />"),
            }}>
          </p>
        </div>
      </div>
      <div
        data-aos="fade-left"
        data-aos-easing="linear"
        data-aos-duration="1000"
        data-aos-delay="500"
        className="order-0 lg:order-1"
      >
        <img
          src={isAbout?.image_url}
          alt=""
          className="rounded-lg w-[70vw] md:w-[50vw] lg:max-w-[30vw] mx-auto"
        />
      </div>
    </div>
  );
}

export default SectionOne;
