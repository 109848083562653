import React, { useRef, useState } from "react";
import modera from "../../Assets/Images/12.jpeg";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { newBaseUrl } from "../../Consts";
import { toast, ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";

function Form({ title }) {
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState(null);
  const [showPopup, setShowPopup] = useState(false); // State to toggle the popup
  const formRef = useRef(null);
  const [selectedFileError, setSelectedFileError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const maxSizeInBytes = 2 * 1024 * 1024; // 2MB
      if (file.size > maxSizeInBytes) {
        alert("File size exceeds 2MB. Please upload a smaller file.");
        event.target.value = null;
        return;
      }
      const allowedTypes = ["application/pdf"];
      if (!allowedTypes.includes(file.type)) {
        alert("Only PDF files are allowed.");
        event.target.value = null;
        return;
      }
      setSelectedFile(file);
      setSelectedFileError(null)
    }
  };

  const handleSendClick = async (event) => {
    setLoading(true);
    if (!selectedFile) {
      setSelectedFileError('A7a')
    }
    event.preventDefault();
    const formData = new FormData();
    formData.append("name", document.getElementById("name").value);
    formData.append("email", document.getElementById("email").value);
    formData.append("phone", document.getElementById("phone").value);
    formData.append("subject", title);
    if (selectedFile) {
      formData.append("resume", selectedFile);
    }

    try {
      const response = await axios.post(`${newBaseUrl}/api/resume`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setLoading(false);
      setShowPopup(true); // Show popup on success
      formRef.current.reset();
      setSelectedFile(null);
      setSelectedFileError(null)
    } catch (err) {
      // Check if there are validation errors in the response
      if (err.response && err.response.data && err.response.data.errors) {
        // Loop through each error message and display it
        const errors = err.response.data.errors; // Assuming `errors` is an object or array
        for (const key in errors) {
          if (errors.hasOwnProperty(key)) {
            errors[key].forEach((message) => {
              toast.error(message); // Display each error message
            });
          }
        }
      } else if (err.response && err.response.data && err.response.data.message) {
        // Display a single error message if available
        toast.error(err.response.data.message);
      } else {
        // Handle other errors
        toast.error("An unexpected error occurred.");
      }
      setLoading(false)
    }
  };

  const closePopup = () => {
    setShowPopup(false); // Close the popup
  };

  return (
    <div className="flex flex-col lg:flex-row items-start justify-between mt-16 mb-10 w-full rounded-md">
      <ToastContainer autoClose={5000} pauseOnFocusLoss={false} pauseOnHover={false} />
      <form ref={formRef} onSubmit={handleSendClick} className="w-full me-10 order-1 lg:order-0"
      >
        <div className="flex flex-col items-start">
          <label
            htmlFor="name"
            className="text-[#141414] text-start font-[600] py-3"
            style={{ fontSize: "clamp(1rem, 1vw, 4rem)" }}
          >
            {t("Name")}
          </label>
          <input
            type="text"
            id="name"
            placeholder={t("Enter Your Name")}
            className="border-[1px] py-3 px-5 w-full rounded-md"
            style={{ fontSize: "clamp(.75rem, .75vw, 4rem)" }}
            required
          />
        </div>
        <div className="flex flex-col items-start">
          <label
            htmlFor="email"
            className="text-[#141414] text-start font-[600] py-3"
            style={{ fontSize: "clamp(1rem, 1vw, 4rem)" }}
          >
            {t("Email")}
          </label>
          <input
            type="email"
            id="email"
            placeholder={t("Enter Your Email")}
            className="border-[1px] py-3 px-5 w-full rounded-md"
            style={{ fontSize: "clamp(.75rem, .75vw, 4rem)" }}
            required
          />
        </div>
        <div className="flex flex-col items-start">
          <label
            htmlFor="phone"
            className="text-[#141414] text-start font-[600] py-3"
            style={{ fontSize: "clamp(1rem, 1vw, 4rem)" }}
          >
            {t("Phone")}
          </label>
          <input
            id="phone"
            placeholder={t("EnterYourPhone")}
            className="border-[1px] py-3 px-5 w-full rounded-md"
            style={{ fontSize: "clamp(.75rem, .75vw, 4rem)" }}
            required
          />
        </div>
        <div className="flex flex-col items-start">
          <label
            htmlFor="fileInput"
            className="text-[#141414] text-start font-[600] py-3"
            style={{ fontSize: "clamp(1rem, 1vw, 4rem)" }}
          >
            {t("Resume")}
          </label>
          <input
            id="fileInput"
            type="file"
            className="hidden"
            accept="application/pdf"
            onChange={handleFileChange}
          />
          <label
            htmlFor="fileInput"
            className="cursor-pointer bg-gray-200 py-2 px-4 rounded-md"
            style={{ fontSize: "clamp(.75rem, .75vw, 4rem)" }}
          >
            {selectedFile ? selectedFile.name : t("Choose File")}
          </label>
          {selectedFileError != null ? <span className="text-red-500 pt-3"
            style={{ fontSize: "clamp(.75rem, .75vw, 4rem)" }}>{t("resumeRequired")}</span> : <span></span>}
        </div>
        <button
          type="submit"
          className="bg-[#0A66B0] hover:bg-transparent border-[#0A66B0 border-[1px] block mx-auto w-[20vw] lg:w-[10vw] hover:border-[#0A66B0] text-[#FFF] hover:text-[#0A66B0] font-[400] py-2 rounded-md mt-10"
          style={{ fontSize: "clamp(.75rem, .75vw, 4rem)" }}
          disabled={loading}
        >
          {t("Send")}
        </button>
      </form>
      <div className="order-0 lg:order-1">
        <img
          src={modera}
          className="rounded-lg w-[100vw] md:w-[100vw] lg:max-w-[40vw] mx-auto"
        />
      </div>
      {/* Popup */}
      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="flex flex-col bg-white p-5 rounded-lg shadow-lg text-center justify-center h-[200px]">
            <p className="text-lg font-bold">{t("resumeSentSuccessfully")}</p>
            <div className="flex lg:flex-row flex-col justify-between gap-5 mt-10">
              <Link
                to={"/"}
              >
                <button
                  onClick={closePopup}
                  className="bg-[#0A66B0] hover:bg-transparent border-[#0A66B0 border-[1px] block mx-auto w-[80vw] lg:w-[10vw] hover:border-[#0A66B0] text-[#FFF] hover:text-[#0A66B0] font-[400] py-2 rounded-md"
                >
                  {t("goToHome")}
                </button>
              </Link>
              <Link
                to={"/careers"}
              >
                <button
                  onClick={closePopup}
                  className="py-2 w-[80vw] lg:w-[10vw] rounded-md border-[1px] border-[#0A66B0] mx-auto text-[#0A66B0] hover:bg-[#0A66B0] hover:text-[#FFF] font-[400]"
                >
                  {t("discoverOtherJobs")}
                </button>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Form;
