import React, { useEffect, useState } from "react";
import banner from "../../Assets/Images/fitout.jpg";
import fit from "../../Assets/Images/FITOUT.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function LatestProducts() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col lg:flex-row md:flex-row items-center my-14">
      <div className="relative w-[100%] h-[50vw] md:h-[40vw] lg:w-[50%] lg:h-[20vw]">
        {/* Background Image with Overlay */}
        <div
          className="absolute inset-0 bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: `url(${banner})`,
          }}
        >
        </div>
      </div>
      <div className="relative w-[100%] h-[50vw] md:h-[40vw] lg:w-[50%] lg:h-[20vw]">
        {/* Background Image with Overlay */}
        <div
          className="absolute inset-0 bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: `url(${fit})`,
          }}
        >
          <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        </div>

        {/* Content on Image */}
        <div className="career-title absolute inset-0 flex flex-col items-center justify-center text-center">
          <h1
            data-aos="fade-in"
            data-aos-duration="1000"
            data-aos-easing="linear"
            data-aos-delay="500"
            className="text-white font-[700]"
            style={{ fontSize: "clamp(1.7rem, 2.5vw, 4rem)" }}
          >
            {t("latestProducts").split("\n").map((i, key) => (
              <div key={key}>{i}</div>
            ))}
          </h1>
          <Link
            data-aos="fade-in"
            data-aos-duration="1000"
            data-aos-easing="linear"
            data-aos-delay="500"
            to={"/products"}
          >
            <button className="mt-5 py-2 px-6 rounded-md border-[1px] border-[#af1f23] hover:border-[#fff] bg-[#af1f23] text-[#fff] hover:bg-transparent hover:text-[#FFF] font-[400]"
              style={{ fontSize: "clamp(1.1rem, 1.1vw, 4rem)" }}>
              {t("ShowMore")}
            </button>
          </Link>
        </div>
      </div>

    </div>
  );
}

export default LatestProducts;
