import React, { useEffect } from "react";
import SectionTwo from "../Components/Product/SectionTwo";
import SectionOne from "../Components/Product/SectionOne";
import { useNavigate, useParams } from "react-router-dom";
import { useProducts } from "../Context/ProductsContext";

function Product() {
  const { getProductById, isProduct } = useProducts();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      await getProductById(id); // Fetch project data when the component is mounted
    };

    fetchData();
    window.scrollTo({ top: 0, behavior: "auto" }); // Scroll to top on load
  }, [id]);

  if (isProduct === null) {
    navigate(`/NotFound`, { replace: true }); // Redirect if there's no data
    return null; // Don't render anything while navigating
  }


  return (
    <div className="overlay">
      <div className="flex flex-col mx-[2rem] lg:mx-[7rem] md:mx-[4rem] items-center gap-7">
        <SectionOne isProduct={isProduct?.data?.main_product} />
        {isProduct?.data.related_products.length > 0 ?
          <SectionTwo isProduct={isProduct?.data.related_products} />
          : <div></div>}
      </div>
    </div>
  );
}

export default Product;
