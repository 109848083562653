import React, { useEffect, useState } from "react";
import SectionOne from "../Components/Project/SectionOne";
import SectionTwo from "../Components/Project/SectionTwo";
import SectionThree from "../Components/Project/SectionThree";
import { useProjects } from "../Context/ProjectsContext";
import { useParams, useNavigate } from "react-router-dom";

function Project() {
  const { getProject, isProject } = useProjects();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      await getProject(id); // Fetch project data when the component is mounted
    };

    fetchData();
    window.scrollTo({ top: 0, behavior: "auto" }); // Scroll to top on load
  }, [id]);

  if (isProject === null) {
    navigate(`/NotFound`, { replace: true }); // Redirect if there's no data
    return null; // Don't render anything while navigating
  }

  return (
    <div className="overlay">
      <div className="flex flex-col mx-[2rem] lg:mx-[7rem] md:mx-[4rem] items-center gap-7">
        <SectionOne isProject={isProject?.data?.main_project} />
        <SectionTwo isProject={isProject?.data.main_project.description} />
      </div>
      {isProject?.data.related_projects.length > 0 ?
        < SectionThree isProject={isProject?.data.related_projects} />
        : <div></div>}
    </div>
  );
}

export default Project;
