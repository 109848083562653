import React, { useEffect } from "react";
import Cards from "../Components/Services/Cards";
import { usePagesBanners } from "../Context/PagesBannersContext";
import Banner from "../Components/Services/Banner";

function Services() {
  const { isBanner } = usePagesBanners();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);
  return (
    <div className="flex flex-col mx-[2rem] lg:mx-[7rem] md:mx-[4rem] items-center gap-7 overlay">
        <Banner isBanner={isBanner?.data[1]}/>
        <Cards />
    </div>
  );
}

export default Services;
