import React from "react";

function SectionOne({ isNewsDetails }) {

  return (
    <div className="flex flex-col lg:flex-row mt-[10rem] lg:items-start items-start gap-5 lg:gap-10 w-full">
      <div data-aos="fade-in"
        data-aos-duration="1000"
        data-aos-easing="linear"
        data-aos-delay="500"
        className="flex flex-col lg:w-[40vw] lg:flex-row-reverse justify-end items-center gap-7">
        <img src={isNewsDetails?.image_url} alt=""
          className="w-[100vw] h-[70vw] md:w-[100vw] md:h-[70vw] lg:w-[65vw] lg:h-[30vw] xl:max-h-[25vw] xl:w-[40vw] rounded-md cursor-pointer"
        />
      </div>
      <div
        data-aos="fade-left"
        data-aos-duration="1000"
        data-aos-easing="linear"
        data-aos-delay="500"
        className="flex flex-col text-start gap-[1rem] lg:gap-6 lg:w-[50vw]"
        style={{ fontSize: "clamp(1.1rem, 1.1vw, 4rem)" }}>
        <h1 className="text-[#0A66B0] text-[20px] lg:text-[32px] font-[700]"
          style={{ fontSize: "clamp(1.6rem, 1.6vw, 4rem)" }}>
          {isNewsDetails?.title}
        </h1>
        <p className="text-start text-[#141414] font-[400]"
        >
          {isNewsDetails?.excerpt}
        </p>
        <span className="border"></span>
        <p className="text-start text-[#141414] font-[400]">
          <span className="text-[#5F5F5F]"></span>{isNewsDetails?.date}
        </p>
      </div>
    </div>
  );
}

export default SectionOne;
