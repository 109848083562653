import React, { useEffect } from "react";
import Banner from "../Components/AllProducts/Banner";
import Cards from "../Components/AllProducts/Cards";
import { useProducts } from "../Context/ProductsContext";
import { useParams } from "react-router-dom";
import { usePagesBanners } from "../Context/PagesBannersContext";

function AllProducts() {
  const { getProductsByCategory, isProductsByCategory } = useProducts();
  const { isBanner } = usePagesBanners();

  useEffect(() => {
    getProductsByCategory('all');
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);
  return (
    <div className="flex flex-col mx-[2rem] lg:mx-[7rem] md:mx-[4rem] items-center gap-7 overlay">
        <Banner isBanner={isBanner?.data[3]}/>
        <Cards isProductsByCategory={isProductsByCategory} />
    </div>
  );
}

export default AllProducts;
