import React from "react";
import Carousel from "./Carousel";

function Img({ isHome }) {
  const images = isHome.map((image, index) => ({
    data: image,
  }));

  return (
    <div>
      <main>
        <Carousel isHome={images} />
      </main>
    </div>
  );
}

export default Img;
