import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function AboutSection({ isHome }) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-between lg:flex-row gap-14 my-5 pt-5">
      <div className="flex flex-col gap-2  order-1 lg:order-0">
        <div
          data-aos="fade-right"
          data-aos-duration="1000"
          data-aos-easing="linear"
          data-aos-delay="500"
          className="relative lg:services-title flex flex-col items-start "
        >
          <h1 className="text-[#AF1F24] font-[400]"
            style={{ fontSize: "clamp(1.6rem, 1.6vw, 4rem)" }}>
            {t("WHO WE ARE")}
          </h1>
          <h1 className="text-[#0A66B0] font-[600]"
            style={{ fontSize: "clamp(2rem, 2.25vw, 4rem)" }}>
            {t("About")}
          </h1>
        </div>
        <div
          data-aos="fade-right"
          data-aos-duration="1000"
          data-aos-easing="linear"
          data-aos-delay="500"
          className="flex flex-col items-start gap-5 lg:max-w-[60vw]"
        >
          <p className="text-start text-[#141414] text-[16px] lg:text-[24px] font-[400]">
            {isHome?.excerpt != null ? isHome?.excerpt : null}
          </p>
          {isHome?.excerpt != null ? <span className="border"></span> : null}
          <p
            className="text-start text-[#141414]"
            style={{ fontSize: "clamp(1.1rem, 1.1vw, 4rem)" }}
            dangerouslySetInnerHTML={{
              __html: isHome?.description.replace(/\n/g, "<br />"),
            }}>
          </p>
        </div>
        <Link
          data-aos="fade-in"
          data-aos-duration="1000"
          data-aos-easing="linear"
          data-aos-delay="500"
          className="me-auto" to={"/about"}>
          <button className="mt-5 py-2 px-6 rounded-md border-[1px] border-[#0A66B0] text-[#0A66B0] hover:bg-[#0A66B0] hover:text-[#FFF] font-[400]"
            style={{ fontSize: "clamp(1.1rem, 1.1vw, 4rem)" }}>
            {t("ViewMore")}
          </button>
        </Link>
      </div>
      <div
        data-aos="fade-left"
        data-aos-duration="1000"
        data-aos-easing="linear"
        data-aos-delay="500"
        className="order-0 lg:order-1"
      >
        <img
          src={isHome?.image_url}
          alt=""
          className="rounded-md w-[70vw] md:w-[50vw] lg:max-w-[30vw] mx-auto"
        />
      </div>
    </div>
  );
}

export default AboutSection;
