import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useProducts } from "../../Context/ProductsContext";

function Cards() {
  const { id } = useParams();
  const { getProductsSubBrands, isProductsSubBrands } = useProducts();

  useEffect(() => {
    getProductsSubBrands(id);
  }, []);

  const [hoveredSubBrandId, setHoveredSubBrandId] = useState(null);

  return (
    <div className="flex flex-col gap-12 my-10 justify-center w-[100%] ">

      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-6 gap-6 mt-10"
        dir="ltr"
      >
        {isProductsSubBrands?.data?.map((subBrand) => (
          <div
            key={subBrand.id}
            className="relative bg-[#F2F2F2] rounded-md"
            onMouseEnter={() => setHoveredSubBrandId(subBrand.id)}
            onMouseLeave={() => setHoveredSubBrandId(null)}
            data-aos="fade-in"
            data-aos-duration="1000"
            data-aos-easing="linear"
            data-aos-delay="500"
          >
            <img
              src={subBrand.image_url}
              alt=""
              className="w-[100vw] h-[40vw] sm:w-[30vw] sm:h-[30vw] md:w-[50vw] md:h-[35vw] lg:w-[50vw] lg:h-[22vw] xl:w-[50vw] xl:h-[14vw] rounded-md transform transition-transform duration-500 ease-in-out group-hover:scale-110"
            />
            {hoveredSubBrandId === subBrand.id && (
              <div className="absolute inset-0 grid place-items-center bg-[#00000030] rounded-md">
                <div className="items-center flex flex-col gap-2 justify-center">
                  <Link to={`/order/ ${subBrand.name}`}>
                    <button className="bg-[#0A66B0] text-[#FFF] font-[600] py-1 px-5 rounded-md">
                      Ask your price
                    </button>
                  </Link>
                  <Link to={subBrand.catalog_url} target="_blank">
                    <button className="bg-[#AF1F24] text-[#FFF] font-[600] py-1 px-5 rounded-md">
                      Download
                    </button>
                  </Link>
                </div>
              </div>
            )}
          </div>
        ))
        }
      </div >
    </div >
  );
}

export default Cards;
