import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useTranslation } from "react-i18next";

const responsive = {
  largeDesktop: {
    breakpoint: { max: 4000, min: 1600 }, // Screens larger than 1600px
    items: 5, // Show 6 items on large desktop screens
  },
  desktop: {
    breakpoint: { max: 1600, min: 1200 }, // Screens between 1200px and 1600px
    items: 4, // Show 5 items on desktop screens
  },
  tablet: {
    breakpoint: { max: 1199, min: 768 }, // Screens between 768px and 1199px
    items: 3, // Show 3 items on tablet screens
  },
  smallTablet: {
    breakpoint: { max: 1024, min: 768 }, // More specific tablet range (768px to 1024px)
    items: 3, // Show 2 items on small tablets
  },
  mobile: {
    breakpoint: { max: 768, min: 576 }, // Screens between 576px and 768px
    items: 3, // Show 2 items on mobile screens (larger than 576px but smaller than 768px)
  },
  smallMobile: {
    breakpoint: { max: 575, min: 0 }, // Screens smaller than 576px
    items: 1, // Show 1 item on small mobile screens
  },
};

function SectionTwo({ isProduct }) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-5 w-full ">
      <div className="text-start">
        <h1 className="text-[#0A66B0] font-[600]"
          style={{ fontSize: "clamp(2rem, 2.25vw, 4rem)" }}>
          {t("RelatedProducts")}
        </h1>
      </div>
      {isProduct?.length > 0 ? (
        <Carousel
          responsive={responsive}
          infinite
          autoPlay
          autoPlaySpeed={3000}
          showDots={false}
          arrows
          containerClass="carousel-container"
          itemClass="px-3" // Adds spacing between items
        >
          {isProduct.map((product) => (
            <div
              key={product.id}
              className="flex justify-between items-start gap-[2rem] lg:gap-10 overflow-hidden"
              dir="auto"
            >
              <div
                data-aos="fade-left"
                data-aos-duration="1000"
                data-aos-easing="linear"
                data-aos-delay="500"
                className="relative group flex flex-col items-start gap-[15px] w-full lg:w-[25vw]"
              >
                {/* Image Container with Hover Effect */}
                <div className="rounded-md overflow-hidden">
                  <Link to={`/product/${product.id}`}>
                    <img
                      src={product.image_url}
                      alt=""
                      className="w-[100vw] h-[75vw] md:w-[50vw] md:h-[25vw] lg:w-[50vw] lg:h-[22vw] xl:w-[50vw] xl:h-[16vw] rounded-md object-cover transform transition-transform duration-500 ease-in-out group-hover:scale-110"
                    />
                  </Link>
                </div>

                {/* Product Title with Hover Effect */}
                <h1
                  className="text-[#212121] group-hover:text-[#0A66B0] transition-colors duration-300"
                  style={{ fontSize: "clamp(1.1rem, 1.1vw, 4rem)" }}
                >
                  {product.title}
                </h1>
              </div>
            </div>
          ))}
        </Carousel>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default SectionTwo;
