import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useCareers } from "../../Context/CareersContext";

function Cards() {
  const { isCareers, getCareers } = useCareers();
  // console.log("hello", isCareers);
  const { t } = useTranslation();
  useEffect(() => {
    getCareers();
  }, []);
  return (
    <div className="flex flex-col gap-12 my-10 justify-center w-[100%]">
      <div className="flex flex-col gap-14">
        {isCareers?.data?.map((job) => (
          <div
            key={job.id}
            data-aos="fade-in"
            data-aos-duration="1000"
            data-aos-easing="linear"
            data-aos-delay="500"
            className="flex flex-col justify-between items-center lg:flex-row px-14 py-8 lg:gap-10 shadow-custom border-r-[10px] border-[#0A66B0] rounded-md"
          >
            <div className="flex flex-col items-start gap-5 w-[60vw]"
              style={{ fontSize: "clamp(.75rem, .75vw, 4rem)" }}>
              <h1 className="text-[#0A66B0] font-[600]"
                style={{ fontSize: "clamp(1.5rem, 1.5vw, 4rem)" }}>
                {job.title}
              </h1>
              <div className="flex flex-col lg:flex-row gap-3 lg:gap-8">
                <h3 className="text-[#AF1F24] text-start font-[400]">
                  {t("jobLocation")} : {job.location}
                </h3>
                <h3 className="text-[#AF1F24] text-start font-[400]">
                  {t("jobPosition")} : {job.position}
                </h3>
                <h3 className="text-[#AF1F24] text-start font-[400]">
                  {t("empStatus")} : {job.emp_status}
                </h3>
              </div>
              <p className="text-start text-[#141414] font-[400]">
                {job.excerpt}
              </p>
            </div>
            <Link to={"/career/" + job.id}>
              <button className="mt-5 py-2 px-6 rounded-md border-[1px] border-[#0A66B0] text-[#0A66B0] hover:bg-[#0A66B0] hover:text-[#FFF] font-[400]"
                style={{ fontSize: "clamp(.75rem, .75vw, 4rem)" }}>
                {t("ViewDetails")}
              </button>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Cards;
